<template>
  <b-row>
    <b-col cols="12" v-show="displayForm">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit, failed }" slim>
        <b-form @submit.prevent="handleSubmit(registerEvent)">
          <b-tabs pills fill v-model="activeTab">
            <b-tab :title="$t('event_registrierung.registrierung.label_veranstaltungs_kategorie')" class="pt-3" active>
              <ValidationObserver ref="observer" v-slot="{ handleSubmit, failed }" slim>
                <b-row class="mobile-tabs pb-4">
                  <b-col class="text-center">
                    <b-link
                      @click="prevTab"
                      class="d-inline-block pr-1 pr-sm-4"
                    >
                      <font-awesome-icon icon="angle-double-left" />
                    </b-link>
                    {{ $t('event_registrierung.registrierung.label_veranstaltungs_kategorie') }}
                    <b-link
                      @click="handleSubmit(nextTab)"
                      class="d-inline-block pl-1 pl-sm-4"
                    >
                      <font-awesome-icon icon="angle-double-right" />
                    </b-link>
                  </b-col>
                </b-row>
                <!-- Feld: Wettkampftyp (Vorausgewählt, je nach Platform) -->
                <b-row>
                  <b-col>
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_wettkampftyp')"
                      rules="required"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-nwpType"
                        label-cols-sm="4"
                        label-cols-lg="3"
                        content-cols-sm
                        content-cols-lg="9"
                        :label="$t('event_registrierung.registrierung.label_wettkampftyp') + ' *'"
                        label-for="input-nwpType"
                      >
                        <b-form-select
                          id="input-nwpType"
                          v-model="form.nwpType"
                          :options="selectFields.nwpType"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-nwpType"
                          size="sm"
                          class="mt-1"
                        ></b-form-select>
                        <b-form-invalid-feedback id="feedback-nwpType">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>

                <!-- Feld: Wettkampfkategorie -->
                <b-row>
                  <b-col>
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_wettkampfkategorie')"
                      rules="required"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-nwpCategory"
                        label-cols-sm="4"
                        label-cols-lg="3"
                        content-cols-sm
                        content-cols-lg="9"
                        :label="$t('event_registrierung.registrierung.label_wettkampfkategorie') + ' *'"
                        label-for="input-nwpCategory"
                      >
                        <b-form-select
                          id="input-nwpCategory"
                          v-model="form.nwpCategory"
                          :options="selectFields.nwpCategory"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-nwpCategory"
                          size="sm"
                          class="mt-1"
                          @change="nwpCategoryUpdated"
                        ></b-form-select>
                        <b-form-invalid-feedback id="feedback-nwpCategory">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>

                <!-- Feld: Typ der lokal Ausscheidung (Wird nur angezeigt, wenn bei Wettkampfkategorie lokale Ausscheidung gewählt wurde) -->
                <b-row
                  v-if="form.nwpCategory === 'Lov.Sat.NWPCategory.SprintLocalContest' || form.nwpCategory === 'Lov.Sat.NWPCategory.SprintCantonFinal'"
                >
                  <b-col>
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_eventkategorie')"
                      rules="required"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-nwpEligible"
                        label-cols-sm="4"
                        label-cols-lg="3"
                        content-cols-sm
                        content-cols-lg="9"
                        :label="$t('event_registrierung.registrierung.label_eventkategorie') + ' *'"
                        label-for="input-nwpEligible"
                      >
                        <b-form-select
                          id="input-nwpEligible"
                          v-model="form.nwpEligible"
                          :options="form.nwpCategory === 'Lov.Sat.NWPCategory.SprintLocalContest' ? selectFields.nwpEligibleLocal : selectFields.nwpEligibleCanton"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-nwpEligible"
                          size="sm"
                          class="mt-1"
                        ></b-form-select>
                        <b-form-invalid-feedback id="feedback-nwpEligible">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>

                <b-row align-h="between">
                  <b-col cols="6" xl="2" lg="3" md="4">
                    <b-button block @click="prevTab" variant="light"
                      >{{ $t('event_registrierung.registrierung.label_back') }}</b-button
                    >
                  </b-col>
                  <b-col cols="6" xl="2" lg="3" md="4">
                    <b-button block @click="handleSubmit(nextTab)"
                      >{{ $t('event_registrierung.registrierung.label_continue') }}</b-button
                    >
                  </b-col>
                  <b-col cols="12" class="text-danger text-right" v-if="failed">
                    <small>{{ $t('event_registrierung.registrierung.alert_form_validation_failed') }}</small>
                  </b-col>
                </b-row>
              </ValidationObserver>
            </b-tab>
            <b-tab :title="$t('event_registrierung.registrierung.label_veranstaltung')" class="pt-3">
              <ValidationObserver ref="observer" v-slot="{ handleSubmit, failed }" slim>
                <b-row class="mobile-tabs pb-4">
                  <b-col class="text-center">
                    <b-link @click="prevTab" class="d-inline-block pr-4">
                      <font-awesome-icon icon="angle-double-left" />
                    </b-link>
                    {{ $t('event_registrierung.registrierung.label_veranstaltung') }}
                    <b-link
                      @click="handleSubmit(nextTab)"
                      class="d-inline-block pl-4"
                    >
                      <font-awesome-icon icon="angle-double-right" />
                    </b-link>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="6">
                    <!-- Feld: Veranstalter (Vorausgefüllt, wenn angemeldet) -->
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_veranstalter_verein')"
                      rules="required"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-schoolOrOrganisation"
                        :label="$t('event_registrierung.registrierung.label_veranstalter_verein') + ' *'"
                        label-for="input-schoolOrOrganisation"
                      >
                        <b-form-input
                          id="input-schoolOrOrganisation"
                          v-model="form.schoolOrOrganisation"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-schoolOrOrganisation"
                          size="sm"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          id="feedback-schoolOrOrganisation"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col sm="6">
                    <!-- Feld: Wettkampfname -->
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_wettkampfname')"
                      rules="required"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-nwpName"
                        :label="$t('event_registrierung.registrierung.label_wettkampfname') + ' *'"
                        label-for="input-nwpName"
                      >
                        <b-form-input
                          id="input-nwpName"
                          v-model="form.nwpName"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-nwpName"
                          size="sm"
                        ></b-form-input>
                        <b-form-invalid-feedback id="feedback-nwpName">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col sm="6">
                    <!-- Feld: Homologation (Reset Sportanlage / Stadion bei update) -->
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_homologation')"
                      rules="required"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-homologation"
                        :label="$t('event_registrierung.registrierung.label_homologation') + ' *'"
                        :description="$t('event_registrierung.registrierung.label_homologation_description')"
                        label-for="input-homologation"
                      >
                        <b-form-select
                          id="input-homologation"
                          v-model="helper.veranstaltungHomologation"
                          :options="selectFields.veranstaltungHomologation"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-homologation"
                          @change="resetSportanlage"
                          size="sm"
                        ></b-form-select>
                        <b-form-invalid-feedback id="feedback-homologation">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col
                    sm="6"
                    v-if="helper.veranstaltungHomologation == 'homologiert'"
                  >
                    <!-- Feld: Sportanlage / Stadion (Wird angezeigt wenn Homologation "JA") -->
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_sportanlage_stadion')"
                      rules="required"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-sportFieldId"
                        :label="$t('event_registrierung.registrierung.label_sportanlage_stadion') + ' *'"
                        label-for="input-sportFieldId"
                      >
                        <b-form-select
                          id="input-sportFieldId"
                          v-model="form.sportFieldId"
                          :options="selectFields.sportFieldId"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-sportFieldId"
                          @change="prefillStadiumData"
                          size="sm"
                        ></b-form-select>
                        <b-form-invalid-feedback id="feedback-sportFieldId">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col sm="6" v-else>
                    <!-- Feld: Sportanlage / Stadion (Wird angezeigt wenn Homologation "NEIN") -->
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_sportanlage_stadion')"
                      rules="required"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-sportField"
                        :label="$t('event_registrierung.registrierung.label_sportanlage_stadion') + ' *'"
                        label-for="input-sportField"
                      >
                        <b-form-input
                          id="input-sportField"
                          v-model="form.sportField"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-sportField"
                          size="sm"
                        ></b-form-input>
                        <b-form-invalid-feedback id="feedback-sportField">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>

                <b-row>
                  <!-- Feld: Veranstalungsadresse (Strasse) (Wenn bei "Sportanlage / Stadion" ein vordefiniertes Stadion gewählt ist, wird dies vorausgefüllt) -->
                  <b-col>
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_veranstaltungsadresse_strasse')"
                      rules="required"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-nwpAddress"
                        :label="$t('event_registrierung.registrierung.label_veranstaltungsadresse_strasse') + ' *'"
                        label-for="input-nwpAddress"
                      >
                        <b-form-input
                          id="input-nwpAddress"
                          v-model="form.nwpAddress"
                          :state="getValidationState(validationContext)"
                          :disabled="helper.disableNwpAddress"
                          aria-describedby="feedback-nwpAddress"
                          size="sm"
                        ></b-form-input>
                        <b-form-invalid-feedback id="feedback-nwpAddress">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="3" sm="4">
                    <!-- Feld: Veranstalungsadresse (PLZ) (Wenn bei "Sportanlage / Stadion" ein vordefiniertes Stadion gewählt ist, wird dies vorausgefüllt) -->
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_plz')"
                      rules="required|numeric|length:4"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-nwpZipCode"
                        :label="$t('event_registrierung.registrierung.label_plz') + ' *'"
                        label-for="input-nwpZipCode"
                      >
                        <b-form-input
                          id="input-nwpZipCode"
                          v-model="form.nwpZipCode"
                          :state="getValidationState(validationContext)"
                          :disabled="helper.disableNwpZipCode"
                          aria-describedby="feedback-nwpZipCode"
                          size="sm"
                        ></b-form-input>
                        <b-form-invalid-feedback id="feedback-nwpZipCode">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col md="9" sm="8">
                    <!-- Feld: Veranstalungsadresse (Ort) (Wenn bei "Sportanlage / Stadion" ein vordefiniertes Stadion gewählt ist, wird dies vorausgefüllt) -->
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_veranstaltungsort')"
                      rules="required"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-venue"
                        :label="$t('event_registrierung.registrierung.label_veranstaltungsort') + ' *'"
                        label-for="input-venue"
                      >
                        <b-form-input
                          id="input-venue"
                          v-model="form.venue"
                          :state="getValidationState(validationContext)"
                          :disabled="helper.disableVenue"
                          aria-describedby="feedback-venue"
                          size="sm"
                        ></b-form-input>
                        <b-form-invalid-feedback id="feedback-venue">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>

                <b-row>
                  <!-- Feld: Veranstalungsadresse (Kanton) (Wenn bei "Sportanlage / Stadion" ein vordefiniertes Stadion gewählt ist, wird dies vorausgefüllt) -->
                  <b-col>
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_kanton')"
                      rules="required"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-nwpCanton"
                        :label="$t('event_registrierung.registrierung.label_kanton') + ' *'"
                        label-for="input-nwpCanton"
                      >
                        <b-form-select
                          id="input-nwpCanton"
                          v-model="form.nwpCanton"
                          :options="selectFields.cantonList"
                          :state="getValidationState(validationContext)"
                          :disabled="helper.disableNwpCanton"
                          aria-describedby="feedback-nwpCanton"
                          size="sm"
                        ></b-form-select>
                        <b-form-invalid-feedback id="feedback-nwpCanton">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>

                <b-row>
                  <!-- Feld: Jüngste Kategorie -->
                  <b-col>
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_juengste_kategorie')"
                      rules="required"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-youngestCategory"
                        :label="$t('event_registrierung.registrierung.label_juengste_kategorie') + ' *'"
                        label-for="input-youngestCategory"
                      >
                        <b-form-select
                          id="input-youngestCategory"
                          v-model="form.youngestCategory"
                          :options="selectFields.youngestCategory"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-youngestCategory"
                          size="sm"
                          :disabled="helper.disableYoungestCategory"
                          @change="youngestCategoryUpdated"
                        ></b-form-select>
                        <b-form-invalid-feedback id="feedback-youngestCategory">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
                
                <b-row>
                  <!-- Feld: Webseite -->
                  <b-col>
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_webseite')"
                      :rules="{
                        regex:
                          /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
                      }"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-webpage"
                        :label="$t('event_registrierung.registrierung.label_webseite')"
                        :description="$t('event_registrierung.registrierung.label_webseite_description')"
                        label-for="input-webpage"
                      >
                        <b-form-input
                          id="input-webpage"
                          v-model="form.webpage"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-webpage"
                          placeholder="https://visanasprint.ch"
                          size="sm"
                        ></b-form-input>
                        <b-form-invalid-feedback id="feedback-webpage">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col sm="6">
                    <!-- Feld: Austragungsdatum -->
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_austragungsdatum')"
                      rules="required"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-startDate"
                        :label="$t('event_registrierung.registrierung.label_austragungsdatum') + ' *'"
                        label-for="input-startDate"
                      >
                        <b-form-datepicker
                          id="input-startDate"
                          v-model="form.startDate"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-startDate"
                          :locale="$i18n.locale"
                          start-weekday="1"
                          :min="helper.datePicker.minDate"
                          :hide-header="true"
                          :placeholder="$t('event_registrierung.registrierung.label_austragungsdatum_placeholder')"
                          v-bind="helper.datePicker.labels || {}"
                          size="sm"
                          @input="updateMaxDateDeadline"
                        ></b-form-datepicker>
                        <b-form-invalid-feedback id="feedback-startDate">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col sm="6">
                    <!-- Feld: Startzeit -->
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_startzeit')"
                      rules="required"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-startTime"
                        :description="$t('event_registrierung.registrierung.label_startzeit_description')"
                        :label="$t('event_registrierung.registrierung.label_startzeit') + ' *'"
                        label-for="input-startTime"
                      >
                        <b-form-timepicker
                          id="input-startTime"
                          v-model="helper.startTime"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-startTime"
                          :locale="$i18n.locale"
                          :placeholder="$t('event_registrierung.registrierung.label_startzeit_placeholder')"
                          size="sm"
                          v-bind="helper.timePicker.labels || {}"
                          @input="convertStartTimeToMs"
                        ></b-form-timepicker>
                        <b-form-invalid-feedback id="feedback-startTime">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>

                <b-row>
                  <!-- Feld: Erwartete Teilnehmerzahl -->
                  <b-col>
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_erwartete_teilnehmerzahl')"
                      rules="required|numeric|min_value:0"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-expectedParticipantsCount"
                        :label="$t('event_registrierung.registrierung.label_erwartete_teilnehmerzahl') + ' *'"
                        label-for="input-expectedParticipantsCount"
                      >
                        <b-form-input
                          @change="expectedParticipantsCountUpdated"
                          id="input-expectedParticipantsCount"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-expectedParticipantsCount"
                          type="number"
                          v-model="form.expectedParticipantsCount"
                          :disabled="helper.disableExpectedParticipantsCount"
                          size="sm"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          id="feedback-expectedParticipantsCount"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col sm="6">
                    <!-- Feld: Startgeld erheben -->
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_startgeld_erheben')"
                      rules="required"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-hasEntryFee"
                        :label="$t('event_registrierung.registrierung.label_startgeld_erheben') + ' *'"
                        label-for="input-hasEntryFee"
                      >
                        <b-form-select
                          id="input-hasEntryFee"
                          v-model="form.hasEntryFee"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-hasEntryFee"
                          :options="selectFields.hasEntryFee"
                          :disabled="helper.disableHasEntryFee"
                          size="sm"
                        ></b-form-select>
                        <b-form-invalid-feedback id="feedback-hasEntryFee">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col sm="6" v-if="form.hasEntryFee == 'Lov.Sat.YesNo.Yes'">
                    <!-- Feld: Startgeld (Wird nur angezeigt, wenn "Startgeld erheben" JA ist) -->
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_startgeld')"
                      rules="required|numeric|max_value:10"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-entryFee"
                        :label="$t('event_registrierung.registrierung.label_startgeld') + ' *'"
                        label-for="input-entryFee"
                      >
                        <b-form-input
                          id="input-entryFee"
                          v-model="form.entryFee"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-entryFee"
                          size="sm"
                        ></b-form-input>
                        <b-form-invalid-feedback id="feedback-entryFee">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <!-- Feld: Anmeldeschluss -->
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_anmeldeschluss')"
                      rules="required"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-deadline"
                        :label="$t('event_registrierung.registrierung.label_anmeldeschluss') + ' *'"
                        label-for="input-deadline"
                      >
                        <b-form-datepicker
                          id="input-deadline"
                          v-model="form.deadline"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-deadline"
                          :locale="$i18n.locale"
                          start-weekday="1"
                          :min="helper.datePicker.minDate"
                          :max="helper.datePicker.maxDateDeadline"
                          :hide-header="true"
                          :placeholder="$t('event_registrierung.registrierung.label_anmeldeschluss_placeholder')"
                          v-bind="helper.datePicker.labels || {}"
                          size="sm"
                        ></b-form-datepicker>
                        <b-form-invalid-feedback id="feedback-deadline">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <!-- Feld: Nachmeldungen auf Platz möglich -->
                    <b-form-checkbox
                      id="input-registrationOnSitePossible"
                      v-model="form.registrationOnSitePossible"
                      :value="true"
                      :unchecked-value="false"
                      class="mb-3"
                    >
                      {{ $t('event_registrierung.registrierung.label_nachmeldungen_auf_platz_moeglich') }}
                    </b-form-checkbox>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col sm="6">
                    <!-- Feld: Zeitmessung -->
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_zeitmessung')"
                      rules="required"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-timeMeasure"
                        :label="$t('event_registrierung.registrierung.label_zeitmessung') + ' *'"
                        label-for="input-timeMeasure"
                      >
                        <b-form-select
                          id="input-timeMeasure"
                          v-model="form.timeMeasure"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-timeMeasure"
                          :options="selectFields.timeMeasure"
                          :disabled="helper.disableTimeMeasure"
                          size="sm"
                        ></b-form-select>
                        <b-form-invalid-feedback id="feedback-timeMeasure">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col sm="6">
                    <!-- Feld: Benutzte Auswertungssoftware -->
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_benutzte_auswertungssoftware')"
                      rules="required"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-usedEvaluationSoftware"
                        :label="$t('event_registrierung.registrierung.label_benutzte_auswertungssoftware') + ' *'"
                        label-for="input-usedEvaluationSoftware"
                      >
                        <b-form-select
                          id="input-usedEvaluationSoftware"
                          v-model="form.usedEvaluationSoftware"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-usedEvaluationSoftware"
                          :options="selectFields.UsedEvaluationSoftwareNWP"
                          :disabled="helper.disableUsedEvaluationSoftware"
                          size="sm"
                        ></b-form-select>
                        <b-form-invalid-feedback
                          id="feedback-usedEvaluationSoftware"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col v-if="form.nwpEligible === 'Lov.Sat.NWPEligible.SprintLocalContestRestrictedParticipantGroup'" sm="6">
                    <!-- Feld: Teilnahmeeinschränkung -->
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_teilnahmeeinschränkung')"
                      rules="required"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-participationLimitation"
                        :label="$t('event_registrierung.registrierung.label_teilnahmeeinschränkung') + ' *'"
                        :description="$t('event_registrierung.registrierung.label_teilnahmeeinschränkung_description')"
                        label-for="input-participationLimitation"
                        >
                        <b-form-textarea
                          id="input-participationLimitation"
                          v-model="form.participationLimitation"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-participationLimitation"
                          rows="5"
                          size="sm"
                        ></b-form-textarea>
                        <b-form-invalid-feedback id="feedback-participationLimitation">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col v-if="helper.qualificationForNextStageLic.display" sm="6">
                    <!-- Feld: Qualifikation für nächste Stufe -->
                    <ValidationProvider
                      :name="helper.qualificationForNextStageLic.label"
                      rules="required"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-qualificationForNextStageLic"
                        :label="helper.qualificationForNextStageLic.label + ' *'"
                        label-for="input-qualificationForNextStageLic"
                        >
                        <b-form-select
                          id="input-qualificationForNextStageLic"
                          v-model="form.qualificationForNextStageLic"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-qualificationForNextStageLic"
                          :options="selectFields.nwpQualificationForNextStage"
                          size="sm"
                        ></b-form-select>
                        <b-form-invalid-feedback id="feedback-qualificationForNextStageLic">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                        <b-form-text v-html="helper.qualificationForNextStageLic.description"></b-form-text>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>

                <!-- Feld: Infomail bei Teilnehmeranmeldung -->
                <b-row>
                  <b-col>
                    <b-form-checkbox
                      id="input-infomailOnNewRegistrations"
                      v-model="form.infomailOnNewRegistrations"
                      value="Lov.Sat.YesNo.Yes"
                      unchecked-value="Lov.Sat.YesNo.No"
                      class="mb-3"
                    >
                      {{ $t('event_registrierung.registrierung.label_infomail_bei_teilnehmeranmeldung') }}
                    </b-form-checkbox>
                  </b-col>
                </b-row>

                <b-row align-h="between">
                  <b-col cols="6" xl="2" lg="3" md="4">
                    <b-button block @click="prevTab" variant="light"
                      >{{ $t('event_registrierung.registrierung.label_back') }}</b-button
                    >
                  </b-col>
                  <b-col cols="6" xl="2" lg="3" md="4">
                    <b-button block @click="handleSubmit(nextTab)"
                      >{{ $t('event_registrierung.registrierung.label_continue') }}</b-button
                    >
                  </b-col>
                  <b-col cols="12" class="text-danger text-right" v-if="failed">
                    <small>{{ $t('event_registrierung.registrierung.alert_form_validation_failed') }}</small>
                  </b-col>
                </b-row>
              </ValidationObserver>
            </b-tab>
            <b-tab :title="$t('event_registrierung.registrierung.label_kontakt')" class="pt-3">
              <ValidationObserver ref="observer" v-slot="{ handleSubmit, failed }" slim>
                <b-row class="mobile-tabs pb-4">
                  <b-col class="text-center">
                    <b-link @click="prevTab" class="d-inline-block pr-4">
                      <font-awesome-icon icon="angle-double-left" />
                    </b-link>
                    {{ $t('event_registrierung.registrierung.label_kontakt') }}
                    <b-link
                      @click="handleSubmit(nextTab)"
                      class="d-inline-block pl-4"
                    >
                      <font-awesome-icon icon="angle-double-right" />
                    </b-link>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="6">
                    <!-- Feld: Vorname -->
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_vorname')"
                      rules="required"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-contactFirstName"
                        :label="$t('event_registrierung.registrierung.label_vorname') + ' *'"
                        label-for="input-contactFirstName"
                      >
                        <b-form-input
                          id="input-contactFirstName"
                          v-model="form.contactFirstName"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-contactFirstName"
                          size="sm"
                          @change="prefillShippingFirstName"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          id="feedback-contactFirstName"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col sm="6">
                    <!-- Feld: Nachname -->
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_nachname')"
                      rules="required"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-contactName"
                        :label="$t('event_registrierung.registrierung.label_nachname') + ' *'"
                        label-for="input-contactName"
                      >
                        <b-form-input
                          id="input-contactName"
                          v-model="form.contactName"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-contactName"
                          size="sm"
                          @change="prefillShippingName"
                        ></b-form-input>
                        <b-form-invalid-feedback id="feedback-contactName">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="12">
                    <!-- Feld: Adresse -->
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_adresse')"
                      rules="required"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-contactAddress"
                        :label="$t('event_registrierung.registrierung.label_adresse') + ' *'"
                        label-for="input-contactAddress"
                      >
                        <b-form-input
                          id="input-contactAddress"
                          v-model="form.contactAddress"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-contactAddress"
                          size="sm"
                          @change="prefillShippingAddress"
                        ></b-form-input>
                        <b-form-invalid-feedback id="feedback-contactAddress">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col cols="12">
                    <!-- Feld: Adresszusatz -->
                    <b-form-input
                      id="input-contactAddressLine2"
                      v-model="form.contactAddressLine2"
                      :placeholder="$t('event_registrierung.registrierung.label_adresszusatz_placeholder')"
                      size="sm"
                      class="mb-3"
                      @change="prefillShippingAddressLine2"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="3" sm="4">
                    <!-- Feld: PLZ -->
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_plz')"
                      rules="required|numeric|length:4"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-contactZipCode"
                        :label="$t('event_registrierung.registrierung.label_plz') + ' *'"
                        label-for="input-contactZipCode"
                      >
                        <b-form-input
                          id="input-contactZipCode"
                          v-model="form.contactZipCode"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-contactZipCode"
                          size="sm"
                          @change="prefillShippingZipCode"
                        ></b-form-input>
                        <b-form-invalid-feedback id="feedback-contactZipCode">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col md="9" sm="8">
                    <!-- Feld: Ort -->
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_ort')"
                      rules="required"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-contactCity"
                        :label="$t('event_registrierung.registrierung.label_ort') + ' *'"
                        label-for="input-contactCity"
                      >
                        <b-form-input
                          id="input-contactCity"
                          v-model="form.contactCity"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-contactCity"
                          size="sm"
                          @change="prefillShippingCity"
                        ></b-form-input>
                        <b-form-invalid-feedback id="feedback-contactCity">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>

                <b-row>
                  <!-- Feld: Veranstalungsadresse (Kanton) (Wenn bei "Sportanlage / Stadion" ein vordefiniertes Stadion gewählt ist, wird dies vorausgefüllt) -->
                  <b-col>
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_kanton')"
                      rules="required"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-contactCanton"
                        :label="$t('event_registrierung.registrierung.label_kanton') + ' *'"
                        label-for="input-contactCanton"
                      >
                        <b-form-select
                          id="input-contactCanton"
                          v-model="form.contactCanton"
                          :options="selectFields.cantonList"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-contactCanton"
                          size="sm"
                          @change="prefillShippingCanton"
                        ></b-form-select>
                        <b-form-invalid-feedback id="feedback-contactCanton">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col sm="6">
                    <!-- Feld: Tel. tagsüber / Mobile -->
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_tel_tagsueber_mobile')"
                      rules="required"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-contactMobile"
                        :label="$t('event_registrierung.registrierung.label_tel_tagsueber_mobile') + ' *'"
                        label-for="input-contactMobile"
                        :description="$t('event_registrierung.registrierung.label_tel_tagsueber_mobile_description')"
                      >
                        <b-form-input
                          id="input-contactMobile"
                          v-model="form.contactMobile"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-contactMobile"
                          size="sm"
                          @change="prefillShippingMobile"
                        ></b-form-input>
                        <b-form-invalid-feedback id="feedback-contactMobile">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col sm="6">
                    <!-- Feld: Telefon privat -->
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_telefon_privat')"
                      rules=""
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-contactPhone"
                        :label="$t('event_registrierung.registrierung.label_telefon_privat')"
                        label-for="input-contactPhone"
                      >
                        <b-form-input
                          id="input-contactPhone"
                          v-model="form.contactPhone"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-contactPhone"
                          size="sm"
                          @change="prefillShippingPhone"
                        ></b-form-input>
                        <b-form-invalid-feedback id="feedback-contactPhone">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <!-- Feld: E-Mail -->
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_email')"
                      rules="required|email"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-email"
                        :label="$t('event_registrierung.registrierung.label_email') + ' *'"
                        label-for="input-email"
                      >
                        <b-form-input
                          id="input-email"
                          v-model="form.email"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-email"
                          size="sm"
                          @change="prefillShippingEmail"
                        ></b-form-input>
                        <b-form-invalid-feedback id="feedback-email">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>

                <b-row>
                  <!-- Feld: Sprache -->
                  <b-col>
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_sprache')"
                      rules="required"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-correspondenceLanguage"
                        :label="$t('event_registrierung.registrierung.label_sprache') + ' *'"
                        label-for="input-correspondenceLanguage"
                      >
                        <b-form-select
                          id="input-correspondenceLanguage"
                          v-model="form.correspondenceLanguage"
                          :options="selectFields.correspondenceLanguage"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-correspondenceLanguage"
                          size="sm"
                        ></b-form-select>
                        <b-form-invalid-feedback
                          id="feedback-correspondenceLanguage"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <!-- Feld: Verein / Organisation -->
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_verein_organisation')"
                      rules="required"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-contactOrganisation"
                        :label="$t('event_registrierung.registrierung.label_verein_organisation') + ' *'"
                        label-for="input-contactOrganisation"
                      >
                        <b-form-input
                          id="input-contactOrganisation"
                          v-model="form.contactOrganisation"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-contactOrganisation"
                          size="sm"
                          @change="prefillShippingOrganisation"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          id="feedback-contactOrganisation"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <!-- Feld: IBAN -->
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_iban')"
                      :rules="{required: helper.requireBankAccount, min: 26}"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-iban"
                        :label="helper.requireBankAccount ? $t('event_registrierung.registrierung.label_iban') + ' *' : $t('event_registrierung.registrierung.label_iban')"
                        label-for="input-iban"
                      >
                        <b-form-input
                          id="input-iban"
                          v-model="form.iban"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-iban"
                          size="sm"
                          :formatter="formatIBANInput"
                        ></b-form-input>
                        <b-form-invalid-feedback id="feedback-iban">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col sm="6">
                    <!-- Feld: Tel. Name der Bank -->
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_name_der_bank')"
                      :rules="{required: helper.requireBankAccount}"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-instituteName"
                        :label="helper.requireBankAccount ? $t('event_registrierung.registrierung.label_name_der_bank') + ' *' : $t('event_registrierung.registrierung.label_name_der_bank')"
                        label-for="input-instituteName"
                      >
                        <b-form-input
                          id="input-instituteName"
                          v-model="form.instituteName"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-instituteName"
                          size="sm"
                        ></b-form-input>
                        <b-form-invalid-feedback id="feedback-instituteName">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col sm="6">
                    <!-- Feld: Kontoinhaber -->
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_kontoinhaber')"
                      :rules="{required: helper.requireBankAccount}"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-instituteOwner"
                        :label="helper.requireBankAccount ? $t('event_registrierung.registrierung.label_kontoinhaber') + ' *' : $t('event_registrierung.registrierung.label_kontoinhaber')"
                        label-for="input-instituteOwner"
                      >
                        <b-form-input
                          id="input-instituteOwner"
                          v-model="form.instituteOwner"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-instituteOwner"
                          size="sm"
                        ></b-form-input>
                        <b-form-invalid-feedback id="feedback-instituteOwner">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <!-- Feld: Adresse des Kontoinhabers -->
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_adresse_des_kontoinhabers')"
                      :rules="{required: helper.requireBankAccount}"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-instituteOwnerAddress"
                        :label="helper.requireBankAccount ? $t('event_registrierung.registrierung.label_adresse_des_kontoinhabers') + ' *' : $t('event_registrierung.registrierung.label_adresse_des_kontoinhabers')"
                        label-for="input-instituteOwnerAddress"
                      >
                        <b-form-input
                          id="input-instituteOwnerAddress"
                          v-model="form.instituteOwnerAddress"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-instituteOwnerAddress"
                          size="sm"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          id="feedback-instituteOwnerAddress"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="3" sm="4">
                    <!-- Feld: PLZ des Kontoinhabers -->
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_plz_des_kontoinhabers')"
                      :rules="{required: helper.requireBankAccount, numeric: true, length: 4}"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-instituteOwnerZip"
                        :label="helper.requireBankAccount ? $t('event_registrierung.registrierung.label_plz_des_kontoinhabers') + ' *' : $t('event_registrierung.registrierung.label_plz_des_kontoinhabers')"
                        label-for="input-instituteOwnerZip"
                      >
                        <b-form-input
                          id="input-instituteOwnerZip"
                          v-model="form.instituteOwnerZip"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-instituteOwnerZip"
                          size="sm"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          id="feedback-instituteOwnerZip"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col md="9" sm="8">
                    <!-- Feld: Ort des Kontoinhabers -->
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_ort_des_kontoinhabers')"
                      :rules="{required: helper.requireBankAccount}"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-instituteOwnerLocation"
                        :label="helper.requireBankAccount ? $t('event_registrierung.registrierung.label_ort_des_kontoinhabers') + ' *' : $t('event_registrierung.registrierung.label_ort_des_kontoinhabers')"
                        label-for="input-instituteOwnerLocation"
                      >
                        <b-form-input
                          id="input-instituteOwnerLocation"
                          v-model="form.instituteOwnerLocation"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-instituteOwnerLocation"
                          size="sm"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          id="feedback-instituteOwnerLocation"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>

                <b-row align-h="between">
                  <b-col cols="6" xl="2" lg="3" md="4">
                    <b-button block @click="prevTab" variant="light"
                      >{{ $t('event_registrierung.registrierung.label_back') }}</b-button
                    >
                  </b-col>
                  <b-col cols="6" xl="2" lg="3" md="4">
                    <b-button block @click="handleSubmit(nextTab)"
                      >{{ $t('event_registrierung.registrierung.label_continue') }}</b-button
                    >
                  </b-col>
                  <b-col cols="12" class="text-danger text-right" v-if="failed">
                    <small>{{ $t('event_registrierung.registrierung.alert_form_validation_failed') }}</small>
                  </b-col>
                </b-row>
              </ValidationObserver>
            </b-tab>
            <b-tab :title="$t('event_registrierung.registrierung.label_material')" class="pt-3">
              <ValidationObserver ref="observer" v-slot="{ handleSubmit, failed }" slim>
                <b-row class="mobile-tabs pb-4">
                  <b-col class="text-center">
                    <b-link @click="prevTab" class="d-inline-block pr-4">
                      <font-awesome-icon icon="angle-double-left" />
                    </b-link>
                    {{ $t('event_registrierung.registrierung.label_material') }}
                    <b-link
                      @click="handleSubmit(nextTab)"
                      class="d-inline-block pl-4"
                    >
                      <font-awesome-icon icon="angle-double-right" />
                    </b-link>
                  </b-col>
                </b-row>
                
                <b-row v-if="helper.material.length > 0">
                  <b-col>
                    <b-row v-for="material in helper.material" :key="material.id" class="mb-3">
                      <b-col cols="2">
                        <span class="d-block">
                          {{ $t(material.materialTitleLic) }}
                        </span>
                        <small v-if="material.outOfStock" class="d-block text-danger">
                          {{ $t('event_registrierung.registrierung.label_outOfStock') }}
                        </small>
                      </b-col>
                      <b-col cols="2" class="text-center">
                        <b-img :src="material.image.src" fluid :alt="`Material - ${material.materialTitleLic}`"></b-img>
                      </b-col>
                      <b-col cols="3">
                        <span v-html="material[`description${$i18n.locale.toUpperCase()}`]"></span>
                      </b-col>
                      <b-col cols="2">
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.Startnumber'">
                          <b-col>
                            <!-- Feld: Startnummern (Wird mit Wert aus "expectedParticipantsCount" vorausgefüllt) -->
                            <ValidationProvider
                              :name="$t(material.materialTitleLic)"
                              :rules="{
                                required: true,
                                numeric: true,
                                max_value: form.nwpCategory === 'Lov.Sat.NWPCategory.SprintSwissFinal' ? 400 : form.expectedParticipantsCount,
                                min_value: 0,
                              }"
                              v-slot="validationContext"
                            >
                              <b-form-input
                                id="input-startnumber"
                                v-model="form.startnumber"
                                :state="getValidationState(validationContext)"
                                aria-describedby="feedback-startnumber"
                                type="number"
                                size="sm"
                                :disabled="helper.disableStartnumber || material.outOfStock"
                              ></b-form-input>
                              <b-form-invalid-feedback id="feedback-startnumber">
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.Diploma'">
                          <b-col cols="1">
                            <label for="input-diplomaDE">DE</label>
                          </b-col>
                          <b-col>
                            <!-- Feld: Diplome -->
                            <ValidationProvider
                              :name="$t(material.materialTitleLic)"
                              :rules="{
                                required: true,
                                numeric: true,
                                max_diplomas: [helper.maxDiploma, form.diplomaFR, form.diplomaIT],
                                min_diplomas: !material.outOfStock ? [helper.minDiploma, form.diplomaFR, form.diplomaIT] : [0, form.diplomaFR, form.diplomaIT],
                              }"
                              v-slot="validationContext"
                            >
                              <b-form-input
                                id="input-diplomaDE"
                                v-model="form.diplomaDE"
                                :state="getValidationState(validationContext)"
                                aria-describedby="feedback-diplomaDE"
                                type="number"
                                size="sm"
                                :disabled="material.outOfStock"
                              ></b-form-input>
                              <b-form-invalid-feedback id="feedback-diplomaDE">
                                {{ $t(validationContext.errors[0]) }}
                              </b-form-invalid-feedback>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.Diploma'">
                          <b-col cols="1">
                            <label for="input-diplomaFR">FR</label>
                          </b-col>
                          <b-col>
                            <!-- Feld: Diplome -->
                            <ValidationProvider
                              :name="$t(material.materialTitleLic)"
                              :rules="{
                                required: true,
                                numeric: true,
                                max_diplomas: [helper.maxDiploma, form.diplomaDE, form.diplomaIT],
                                min_diplomas: !material.outOfStock ? [helper.minDiploma, form.diplomaDE, form.diplomaIT] : [0, form.diplomaDE, form.diplomaIT],
                              }"
                              v-slot="validationContext"
                            >
                              <b-form-input
                                id="input-diplomaFR"
                                v-model="form.diplomaFR"
                                :state="getValidationState(validationContext)"
                                aria-describedby="feedback-diplomaFR"
                                type="number"
                                size="sm"
                                :disabled="material.outOfStock"
                              ></b-form-input>
                              <b-form-invalid-feedback id="feedback-diplomaFR">
                                {{ $t(validationContext.errors[0]) }}
                              </b-form-invalid-feedback>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.Diploma'">
                          <b-col cols="1">
                            <label for="input-diplomaIT">IT</label>
                          </b-col>
                          <b-col>
                            <!-- Feld: Diplome -->
                            <ValidationProvider
                              :name="$t(material.materialTitleLic)"
                              :rules="{
                                required: true,
                                numeric: true,
                                max_diplomas: [helper.maxDiploma, form.diplomaDE, form.diplomaFR],
                                min_diplomas: !material.outOfStock ? [helper.minDiploma, form.diplomaDE, form.diplomaFR] : [0, form.diplomaDE, form.diplomaFR],
                              }"
                              v-slot="validationContext"
                            >
                              <b-form-input
                                id="input-diplomaIT"
                                v-model="form.diplomaIT"
                                :state="getValidationState(validationContext)"
                                aria-describedby="feedback-diplomaIT"
                                type="number"
                                size="sm"
                                :disabled="material.outOfStock"
                              ></b-form-input>
                              <b-form-invalid-feedback id="feedback-diplomaIT">
                                {{ $t(validationContext.errors[0]) }}
                              </b-form-invalid-feedback>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.Poster'">
                          <b-col cols="1">
                            <label for="input-posterDE">DE</label>
                          </b-col>
                          <b-col>
                            <!-- Feld: Plakat -->
                            <ValidationProvider
                              :name="$t(material.materialTitleLic)"
                              :rules="{
                                required: true,
                                numeric: true,
                                min_posters: [helper.minPoster, form.posterFR, form.posterIT],
                                max_posters: [helper.maxPoster, form.posterFR, form.posterIT]
                              }"
                              v-slot="validationContext"
                            >
                                <b-form-input
                                  id="input-posterDE"
                                  v-model="form.posterDE"
                                  :state="getValidationState(validationContext)"
                                  aria-describedby="feedback-posterDE"
                                  type="number"
                                  size="sm"
                                  :disabled="material.outOfStock"
                                ></b-form-input>
                                <b-form-invalid-feedback id="feedback-posterDE">
                                  {{ $t(validationContext.errors[0]) }}
                                </b-form-invalid-feedback>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.Poster'">
                          <b-col cols="1">
                            <label for="input-posterFR">FR</label>
                          </b-col>
                          <b-col>
                            <!-- Feld: Plakat -->
                            <ValidationProvider
                              :name="$t(material.materialTitleLic)"
                              :rules="{
                                required: true,
                                numeric: true,
                                min_posters: [helper.minPoster, form.posterDE, form.posterIT],
                                max_posters: [helper.maxPoster, form.posterDE, form.posterIT]
                              }"
                              v-slot="validationContext"
                            >
                                <b-form-input
                                  id="input-posterFR"
                                  v-model="form.posterFR"
                                  :state="getValidationState(validationContext)"
                                  aria-describedby="feedback-posterFR"
                                  type="number"
                                  size="sm"
                                  :disabled="material.outOfStock"
                                ></b-form-input>
                                <b-form-invalid-feedback id="feedback-posterFR">
                                  {{ $t(validationContext.errors[0]) }}
                                </b-form-invalid-feedback>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.Poster'">
                          <b-col cols="1">
                            <label for="input-posterIT">IT</label>
                          </b-col>
                          <b-col>
                            <!-- Feld: Plakat -->
                            <ValidationProvider
                              :name="$t(material.materialTitleLic)"
                              :rules="{
                                required: true,
                                numeric: true,
                                min_posters: [helper.minPoster, form.posterDE, form.posterFR],
                                max_posters: [helper.maxPoster, form.posterDE, form.posterFR]
                              }"
                              v-slot="validationContext"
                            >
                              <b-form-input
                                id="input-posterIT"
                                v-model="form.posterIT"
                                :state="getValidationState(validationContext)"
                                aria-describedby="feedback-posterIT"
                                type="number"
                                size="sm"
                                :disabled="material.outOfStock"
                              ></b-form-input>
                              <b-form-invalid-feedback id="feedback-posterIT">
                                {{ $t(validationContext.errors[0]) }}
                              </b-form-invalid-feedback>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.Poster'" class="mt-3">
                          <b-col>
                            <b-form-checkbox
                              id="input-digitalPoster"
                              v-model="form.digitalPoster"
                              :value="true"
                              :unchecked-value="false"
                              class="mb-3"
                            >
                              {{ $t('event_registrierung.registrierung.label_digitale_version_des_plakates') }}
                            </b-form-checkbox>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.Flyer'">
                          <b-col cols="1">
                            <label for="input-flyerDE">DE</label>
                          </b-col>
                          <b-col>
                            <!-- Feld: Flyer -->
                            <ValidationProvider
                              :name="$t(material.materialTitleLic)"
                              :rules="{
                                required: true,
                                numeric: true,
                                min_value: 0,
                                max_flyers: [form.expectedParticipantsCount, form.flyerFR, form.flyerIT],
                              }"
                              v-slot="validationContext"
                            >
                                <b-form-input
                                  id="input-flyerDE"
                                  v-model="form.flyerDE"
                                  :state="getValidationState(validationContext)"
                                  aria-describedby="feedback-flyerDE"
                                  type="number"
                                  size="sm"
                                  :disabled="material.outOfStock"
                                ></b-form-input>
                                <b-form-invalid-feedback id="feedback-flyerDE">
                                  {{ $t(validationContext.errors[0]) }}
                                </b-form-invalid-feedback>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.Flyer'">
                          <b-col cols="1">
                            <label for="input-flyerFR">FR</label>
                          </b-col>
                          <b-col>
                            <!-- Feld: Flyer -->
                            <ValidationProvider
                              :name="$t(material.materialTitleLic)"
                              :rules="{
                                required: true,
                                numeric: true,
                                min_value: 0,
                                max_flyers: [form.expectedParticipantsCount, form.flyerDE, form.flyerIT],
                              }"
                              v-slot="validationContext"
                            >
                                <b-form-input
                                  id="input-flyerFR"
                                  v-model="form.flyerFR"
                                  :state="getValidationState(validationContext)"
                                  aria-describedby="feedback-flyerFR"
                                  type="number"
                                  size="sm"
                                  :disabled="material.outOfStock"
                                ></b-form-input>
                                <b-form-invalid-feedback id="feedback-flyerFR">
                                  {{ $t(validationContext.errors[0]) }}
                                </b-form-invalid-feedback>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.Flyer'">
                          <b-col cols="1">
                            <label for="input-flyerIT">IT</label>
                          </b-col>
                          <b-col>
                            <!-- Feld: Flyer -->
                            <ValidationProvider
                              :name="$t(material.materialTitleLic)"
                              :rules="{
                                required: true,
                                numeric: true,
                                min_value: 0,
                                max_flyers: [form.expectedParticipantsCount, form.flyerDE, form.flyerFR],
                              }"
                              v-slot="validationContext"
                            >
                              <b-form-input
                                id="input-flyerIT"
                                v-model="form.flyerIT"
                                :state="getValidationState(validationContext)"
                                aria-describedby="feedback-flyerIT"
                                type="number"
                                size="sm"
                                :disabled="material.outOfStock"
                              ></b-form-input>
                              <b-form-invalid-feedback id="feedback-flyerIT">
                                {{ $t(validationContext.errors[0]) }}
                              </b-form-invalid-feedback>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.Flyer'" class="mt-3">
                          <b-col>
                            <b-form-checkbox
                              id="input-digitalFlyer"
                              v-model="form.digitalFlyer"
                              :value="true"
                              :unchecked-value="false"
                              class="mb-3"
                            >
                              {{ $t('event_registrierung.registrierung.label_digitale_version_des_flyers') }}
                            </b-form-checkbox>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.Medal' && (form.nwpCategory === 'Lov.Sat.NWPCategory.SprintCantonFinal' || form.nwpCategory === 'Lov.Sat.NWPCategory.SprintSwissFinal')">
                          <b-col>
                            <!-- Feld: Medaillen (Wird nur angezeigt bei Kantonalfinal oder Schweizerfinal) -->
                            <ValidationProvider
                              :name="$t(material.materialTitleLic)"
                              :rules="{required: true, numeric: true, max_value: helper.maxMedals}"
                              v-slot="validationContext"
                            >
                                <b-form-input
                                  id="input-medal"
                                  v-model="form.medal"
                                  :state="getValidationState(validationContext)"
                                  aria-describedby="feedback-medal"
                                  type="number"
                                  size="sm"
                                  :disabled="helper.disableMedals || material.outOfStock"
                                ></b-form-input>
                                <b-form-invalid-feedback id="feedback-medal">
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.Medal' && form.nwpCategory === 'Lov.Sat.NWPCategory.SprintLocalContest'">
                          <b-col>
                            <!-- Feld: Medaillen Interesse (Wird nur angezeigt bei lokaler Ausscheidung) -->
                            <ValidationProvider
                              :name="$t(material.materialTitleLic)"
                              :rules="{required: !material.outOfStock}"
                              v-slot="validationContext"
                            >
                              <b-form-select
                                  id="input-medalsInterest"
                                  v-model="form.medalsInterestLic"
                                  :options="selectFields.nwpMaterialMedalsInterest"
                                  :state="getValidationState(validationContext)"
                                  aria-describedby="feedback-medalsInterest"
                                  size="sm"
                                  :disabled="helper.disablemedalsInterest || material.outOfStock"
                                ></b-form-select>
                                <b-form-invalid-feedback id="feedback-medalsInterest">
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.HelperShirt'">
                          <b-col cols="1">
                            <label for="input-helperShirtS">S</label>
                          </b-col>
                          <b-col>
                            <!-- Feld: Anzahl Helfershirts Grösse S -->
                            <ValidationProvider
                              vid="helperShirtS"
                              :name="$t(material.materialTitleLic)"
                              :rules="{
                                required: true,
                                numeric: true,
                                max_helperShirts: [helper.maxHelperShirt, form.helperShirtM, form.helperShirtL, form.helperShirtXL]
                              }"
                              v-slot="validationContext"
                            >
                                <b-form-input
                                  id="input-helperShirtS"
                                  v-model="form.helperShirtS"
                                  :state="getValidationState(validationContext)"
                                  aria-describedby="feedback-helperShirtS"
                                  type="number"
                                  size="sm"
                                  :disabled="material.outOfStock"
                                ></b-form-input>
                                <b-form-invalid-feedback id="feedback-helperShirtS">
                                  {{ $t(validationContext.errors[0]) }}
                                </b-form-invalid-feedback>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.HelperShirt'">
                          <b-col cols="1">
                            <label for="input-helperShirtM">M</label>
                          </b-col>
                          <b-col>
                            <!-- Feld: Anzahl Helfershirts Grösse M -->
                            <ValidationProvider
                              vid="helperShirtM"
                              :name="$t(material.materialTitleLic)"
                              :rules="{
                                required: true,
                                numeric: true,
                                max_helperShirts: [helper.maxHelperShirt, form.helperShirtS, form.helperShirtL, form.helperShirtXL]
                              }"
                              v-slot="validationContext"
                            >
                                <b-form-input
                                  id="input-helperShirtM"
                                  v-model="form.helperShirtM"
                                  :state="getValidationState(validationContext)"
                                  aria-describedby="feedback-helperShirtM"
                                  type="number"
                                  size="sm"
                                  :disabled="material.outOfStock"
                                ></b-form-input>
                                <b-form-invalid-feedback id="feedback-helperShirtM">
                                  {{ $t(validationContext.errors[0]) }}
                                </b-form-invalid-feedback>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.HelperShirt'">
                          <b-col cols="1">
                            <label for="input-helperShirtL">L</label>
                          </b-col>
                          <b-col>
                            <!-- Feld: Anzahl Helfershirts Grösse L -->
                            <ValidationProvider
                              vid="helperShirtL"
                              :name="$t(material.materialTitleLic)"
                              :rules="{
                                required: true,
                                numeric: true,
                                max_helperShirts: [helper.maxHelperShirt, form.helperShirtS, form.helperShirtM, form.helperShirtXL]
                              }"
                              v-slot="validationContext"
                            >
                              <b-form-input
                                id="input-helperShirtL"
                                v-model="form.helperShirtL"
                                :state="getValidationState(validationContext)"
                                aria-describedby="feedback-helperShirtL"
                                type="number"
                                size="sm"
                                :disabled="material.outOfStock"
                              ></b-form-input>
                              <b-form-invalid-feedback id="feedback-helperShirtL">
                                {{ $t(validationContext.errors[0]) }}
                              </b-form-invalid-feedback>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.HelperShirt'">
                          <b-col cols="1">
                            <label for="input-helperShirtXL">XL</label>
                          </b-col>
                          <b-col>
                            <!-- Feld: Anzahl Helfershirts Grösse XL -->
                            <ValidationProvider
                              vid="helperShirtXL"
                              :name="$t(material.materialTitleLic)"
                              :rules="{
                                required: true,
                                numeric: true,
                                max_helperShirts: [helper.maxHelperShirt, form.helperShirtS, form.helperShirtM, form.helperShirtL]
                              }"
                              v-slot="validationContext"
                            >
                              <b-form-input
                                id="input-helperShirtXL"
                                v-model="form.helperShirtXL"
                                :state="getValidationState(validationContext)"
                                aria-describedby="feedback-helperShirtXL"
                                type="number"
                                size="sm"
                                :disabled="material.outOfStock"
                              ></b-form-input>
                              <b-form-invalid-feedback id="feedback-helperShirtXL">
                                {{ $t(validationContext.errors[0]) }}
                              </b-form-invalid-feedback>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.HelperCap'">
                          <b-col>
                            <!-- Feld: Anzahl Helfercaps -->
                            <ValidationProvider
                              :name="$t(material.materialTitleLic)"
                              :rules="{required: true, numeric: true, min_value: helper.minHelperCap, max_value: helper.maxHelperCap}"
                              v-slot="validationContext"
                            >
                              <b-form-input
                                id="input-helperCap"
                                v-model="form.helperCap"
                                :state="getValidationState(validationContext)"
                                aria-describedby="feedback-helperCap"
                                type="number"
                                size="sm"
                                :disabled="material.outOfStock"
                              ></b-form-input>
                              <b-form-invalid-feedback id="feedback-helperCap">
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.WinnerPrize'">
                          <b-col>
                            <!-- Feld: Anzahl Siegerpreise -->
                            <ValidationProvider
                              :name="$t(material.materialTitleLic)"
                              rules="required|numeric|min_value:0"
                              v-slot="validationContext"
                            >
                              <b-form-input
                                id="input-winnerPrize"
                                v-model="form.winnerPrize"
                                :state="getValidationState(validationContext)"
                                aria-describedby="feedback-winnerPrize"
                                type="number"
                                size="sm"
                                :disabled="helper.disableWinnerPrize || material.outOfStock"
                              ></b-form-input>
                              <b-form-invalid-feedback id="feedback-winnerPrize">
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.PodiumPrize'">
                          <b-col>
                            <!-- Feld: Anzahl Podestpreise -->
                            <ValidationProvider
                              :name="$t(material.materialTitleLic)"
                              rules="required|numeric|min_value:0"
                              v-slot="validationContext"
                            >
                              <b-form-input
                                id="input-podiumPrize"
                                v-model="form.podiumPrize"
                                :state="getValidationState(validationContext)"
                                aria-describedby="feedback-podiumPrize"
                                type="number"
                                size="sm"
                                :disabled="helper.disablePodiumPrize || material.outOfStock"
                              ></b-form-input>
                              <b-form-invalid-feedback id="feedback-podiumPrize">
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.ParticipantGift'">
                          <b-col>
                            <!-- Feld: Anzahl Teilnehmergeschenke -->
                            <ValidationProvider
                              :name="$t(material.materialTitleLic)"
                              rules="required|numeric|min_value:0"
                              v-slot="validationContext"
                            >
                              <b-form-input
                                id="input-participantGift"
                                v-model="form.participantGift"
                                :state="getValidationState(validationContext)"
                                aria-describedby="feedback-participantGift"
                                type="number"
                                size="sm"
                                disabled
                              ></b-form-input>
                              <b-form-invalid-feedback id="feedback-participantGift">
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.StartingTent'">
                          <b-col>
                            <!-- Feld: Anzahl Startzelt -->
                            <ValidationProvider
                              :name="$t(material.materialTitleLic)"
                              rules="required|numeric|min_value:0"
                              v-slot="validationContext"
                            >
                              <b-form-input
                                id="input-startingTent"
                                v-model="form.startingTent"
                                :state="getValidationState(validationContext)"
                                aria-describedby="feedback-startingTent"
                                type="number"
                                size="sm"
                                :disabled="helper.disableStartingTent || material.outOfStock"
                              ></b-form-input>
                              <b-form-invalid-feedback id="feedback-startingTent">
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.Tent'">
                          <b-col>
                            <!-- Feld: Anzahl Zelt -->
                            <ValidationProvider
                              :name="$t(material.materialTitleLic)"
                              rules="required|numeric|min_value:0"
                              v-slot="validationContext"
                            >
                              <b-form-input
                                id="input-tent"
                                v-model="form.tent"
                                :state="getValidationState(validationContext)"
                                aria-describedby="feedback-tent"
                                type="number"
                                size="sm"
                                :disabled="material.outOfStock"
                              ></b-form-input>
                              <b-form-invalid-feedback id="feedback-tent">
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.Banner'">
                          <b-col>
                            <!-- Feld: Anzahl Banner -->
                            <ValidationProvider
                              :name="$t(material.materialTitleLic)"
                              rules="required|numeric|min_value:0"
                              v-slot="validationContext"
                            >
                              <b-form-input
                                id="input-banner"
                                v-model="form.banner"
                                :state="getValidationState(validationContext)"
                                aria-describedby="feedback-banner"
                                type="number"
                                size="sm"
                                :disabled="helper.disableBanner || material.outOfStock"
                              ></b-form-input>
                              <b-form-invalid-feedback id="feedback-banner">
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.PopOutFlag'">
                          <b-col>
                            <!-- Feld: Anzahl Pop-Out-Flags -->
                            <ValidationProvider
                              :name="$t(material.materialTitleLic)"
                              rules="required|numeric|min_value:0"
                              v-slot="validationContext"
                            >
                              <b-form-input
                                id="input-popOutFlag"
                                v-model="form.popOutFlag"
                                :state="getValidationState(validationContext)"
                                aria-describedby="feedback-popOutFlag"
                                type="number"
                                size="sm"
                                :disabled="helper.disablepopOutFlag || material.outOfStock"
                              ></b-form-input>
                              <b-form-invalid-feedback id="feedback-popOutFlag">
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.BeachFlag'">
                          <b-col>
                            <!-- Feld: Anzahl Beach Flags -->
                            <ValidationProvider
                              :name="$t(material.materialTitleLic)"
                              rules="required|numeric|min_value:0"
                              v-slot="validationContext"
                            >
                              <b-form-input
                                id="input-beachFlag"
                                v-model="form.beachFlag"
                                :state="getValidationState(validationContext)"
                                aria-describedby="feedback-beachFlag"
                                type="number"
                                size="sm"
                                :disabled="helper.disablebeachFlag || material.outOfStock"
                              ></b-form-input>
                              <b-form-invalid-feedback id="feedback-beachFlag">
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.Archway'">
                          <b-col>
                            <!-- Feld: Anzahl Torbögen -->
                            <ValidationProvider
                              :name="$t(material.materialTitleLic)"
                              rules="required|numeric|min_value:0"
                              v-slot="validationContext"
                            >
                              <b-form-input
                                id="input-archway"
                                v-model="form.archway"
                                :state="getValidationState(validationContext)"
                                aria-describedby="feedback-archway"
                                type="number"
                                size="sm"
                                :disabled="helper.disableArchway || material.outOfStock"
                              ></b-form-input>
                              <b-form-invalid-feedback id="feedback-archway">
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.Parasol'">
                          <b-col>
                            <!-- Feld: Anzahl Beach Sonnenschirme -->
                            <ValidationProvider
                              :name="$t(material.materialTitleLic)"
                              :rules="{required: true, numeric: true, min_value: helper.minParasol, max_value: helper.maxParasol}"
                              v-slot="validationContext"
                            >
                              <b-form-input
                                id="input-parasol"
                                v-model="form.parasol"
                                :state="getValidationState(validationContext)"
                                aria-describedby="feedback-parasol"
                                type="number"
                                size="sm"
                                :disabled="helper.disableParasol || material.outOfStock"
                              ></b-form-input>
                              <b-form-invalid-feedback id="feedback-parasol">
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.Table'">
                          <b-col>
                            <!-- Feld: Anzahl Tische -->
                            <ValidationProvider
                              :name="$t(material.materialTitleLic)"
                              rules="required|numeric|min_value:0"
                              v-slot="validationContext"
                            >
                              <b-form-input
                                id="input-table"
                                v-model="form.table"
                                :state="getValidationState(validationContext)"
                                aria-describedby="feedback-table"
                                type="number"
                                size="sm"
                                :disabled="helper.disableTable || material.outOfStock"
                              ></b-form-input>
                              <b-form-invalid-feedback id="feedback-table">
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.Pedestal'">
                          <b-col>
                            <!-- Feld: Anzahl Podeste -->
                            <ValidationProvider
                              :name="$t(material.materialTitleLic)"
                              rules="required|numeric|min_value:0"
                              v-slot="validationContext"
                            >
                              <b-form-input
                                id="input-pedestal"
                                v-model="form.pedestal"
                                :state="getValidationState(validationContext)"
                                aria-describedby="feedback-pedestal"
                                type="number"
                                size="sm"
                                :disabled="helper.disablePedestal || material.outOfStock"
                              ></b-form-input>
                              <b-form-invalid-feedback id="feedback-pedestal">
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.CoolAndCleanBanner'">
                          <b-col>
                            <!-- Feld: Anzahl Cool and Clean Banners -->
                            <ValidationProvider
                              :name="$t(material.materialTitleLic)"
                              rules="required|numeric|min_value:0"
                              v-slot="validationContext"
                            >
                              <b-form-input
                                id="input-coolAndCleanBanner"
                                v-model="form.coolAndCleanBanner"
                                :state="getValidationState(validationContext)"
                                aria-describedby="feedback-coolAndCleanBanner"
                                type="number"
                                size="sm"
                                :disabled="helper.disableCoolAndCleanBanner || material.outOfStock"
                              ></b-form-input>
                              <b-form-invalid-feedback id="feedback-coolAndCleanBanner">
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col cols="3">
                        <span v-html="material[`information${$i18n.locale.toUpperCase()}`]"></span>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>

                <b-row v-else>
                  <b-col>
                    <b-alert variant="warning" show>
                      Für diese Wettkampfkategorie konnte keine Materialliste geladen werden!<br>
                      Stellen Sie sicher, dass Sie bei der Veranstaltungs-Kategorie eine Wettkampfkategorie ausgewählt haben.
                    </b-alert>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <hr />
                    <h3>{{ $t('event_registrierung.registrierung.label_lieferadresse_material') }}</h3>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <b-alert variant="info" show>
                      {{ $t('event_registrierung.registrierung.label_lieferadresse_material_notice') }}
                    </b-alert>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col sm="6">
                    <!-- Feld: Vorname -->
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_vorname')"
                      rules="required"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-shippingFirstName"
                        :label="$t('event_registrierung.registrierung.label_vorname') + ' *'"
                        label-for="input-shippingFirstName"
                      >
                        <b-form-input
                          id="input-shippingFirstName"
                          v-model="form.shippingFirstName"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-shippingFirstName"
                          size="sm"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          id="feedback-shippingFirstName"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col sm="6">
                    <!-- Feld: Nachname -->
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_nachname')"
                      rules="required"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-shippingName"
                        :label="$t('event_registrierung.registrierung.label_nachname') + ' *'"
                        label-for="input-shippingName"
                      >
                        <b-form-input
                          id="input-shippingName"
                          v-model="form.shippingName"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-shippingName"
                          size="sm"
                        ></b-form-input>
                        <b-form-invalid-feedback id="feedback-shippingName">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <!-- Feld: Verein / Schule / Organisation -->
                    <b-form-group
                      id="fieldset-shippingOrganisation"
                      :label="$t('event_registrierung.registrierung.label_verein_schule_organisation')"
                      label-for="input-shippingOrganisation"
                    >
                      <b-form-input
                        id="input-shippingOrganisation"
                        v-model="form.shippingOrganisation"
                        size="sm"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="12">
                    <!-- Feld: Adresse -->
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_adresse')"
                      rules="required"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-shippingAddress"
                        :label="$t('event_registrierung.registrierung.label_adresse') + ' *'"
                        label-for="input-shippingAddress"
                      >
                        <b-form-input
                          id="input-shippingAddress"
                          v-model="form.shippingAddress"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-shippingAddress"
                          size="sm"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          id="feedback-shippingAddress"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col cols="12">
                    <!-- Feld: Adresszusatz -->
                    <b-form-input
                      id="input-shippingAddressLine2"
                      v-model="form.shippingAddressLine2"
                      :placeholder="$t('event_registrierung.registrierung.label_adresszusatz_placeholder')"
                      size="sm"
                      class="mb-3"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="3" sm="4">
                    <!-- Feld: PLZ -->
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_plz')"
                      rules="required|numeric|length:4"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-shippingZipCode"
                        :label="$t('event_registrierung.registrierung.label_plz') + ' *'"
                        label-for="input-shippingZipCode"
                      >
                        <b-form-input
                          id="input-shippingZipCode"
                          v-model="form.shippingZipCode"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-shippingZipCode"
                          size="sm"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          id="feedback-shippingZipCode"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col md="9" sm="8">
                    <!-- Feld: Ort -->
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_ort')"
                      rules="required"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-shippingCity"
                        :label="$t('event_registrierung.registrierung.label_ort') + ' *'"
                        label-for="input-shippingCity"
                      >
                        <b-form-input
                          id="input-shippingCity"
                          v-model="form.shippingCity"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-shippingCity"
                          size="sm"
                        ></b-form-input>
                        <b-form-invalid-feedback id="feedback-shippingCity">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>

                <b-row>
                  <!-- Feld: Lieferkanton -->
                  <b-col>
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_lieferkanton')"
                      rules="required"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-shippingCanton"
                        :label="$t('event_registrierung.registrierung.label_lieferkanton') + ' *'"
                        label-for="input-shippingCanton"
                      >
                        <b-form-select
                          id="input-shippingCanton"
                          v-model="form.shippingCanton"
                          :options="selectFields.cantonList"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-shippingCanton"
                          size="sm"
                        ></b-form-select>
                        <b-form-invalid-feedback id="feedback-shippingCanton">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col sm="6">
                    <!-- Feld: Tel. tagsüber / Mobile -->
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_tel_tagsueber_mobile')"
                      rules="required"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-shippingMobile"
                        :label="$t('event_registrierung.registrierung.label_tel_tagsueber_mobile') + ' *'"
                        label-for="input-shippingMobile"
                      >
                        <b-form-input
                          id="input-shippingMobile"
                          v-model="form.shippingMobile"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-shippingMobile"
                          size="sm"
                        ></b-form-input>
                        <b-form-invalid-feedback id="feedback-shippingMobile">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col sm="6">
                    <!-- Feld: Telefon (Lieferinformation) -->
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_telefon_lieferinformation')"
                      rules=""
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-shippingPhone"
                        :label="$t('event_registrierung.registrierung.label_telefon_lieferinformation')"
                        label-for="input-shippingPhone"
                      >
                        <b-form-input
                          id="input-shippingPhone"
                          v-model="form.shippingPhone"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-shippingPhone"
                          size="sm"
                        ></b-form-input>
                        <b-form-invalid-feedback id="feedback-shippingPhone">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <!-- Feld: E-Mail (Lieferinformation) -->
                    <ValidationProvider
                      :name="$t('event_registrierung.registrierung.label_email_lieferinformation')"
                      rules="email"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="fieldset-shippingEmail"
                        :label="$t('event_registrierung.registrierung.label_email_lieferinformation')"
                        label-for="input-shippingEmail"
                      >
                        <b-form-input
                          id="input-shippingEmail"
                          v-model="form.shippingEmail"
                          :state="getValidationState(validationContext)"
                          aria-describedby="feedback-shippingEmail"
                          size="sm"
                        ></b-form-input>
                        <b-form-invalid-feedback id="feedback-shippingEmail">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>

                <b-row align-h="between">
                  <b-col cols="6" xl="2" lg="3" md="4">
                    <b-button block @click="prevTab" variant="light"
                      >{{ $t('event_registrierung.registrierung.label_back') }}</b-button
                    >
                  </b-col>
                  <b-col cols="6" xl="2" lg="3" md="4">
                    <b-button block @click="handleSubmit(nextTab)"
                      >{{ $t('event_registrierung.registrierung.label_continue') }}</b-button
                    >
                  </b-col>
                  <b-col cols="12" class="text-danger text-right" v-if="failed">
                    <small>{{ $t('event_registrierung.registrierung.alert_form_validation_failed') }}</small>
                  </b-col>
                </b-row>
              </ValidationObserver>
            </b-tab>
            <b-tab :title="$t('event_registrierung.registrierung.label_weiteres')" class="pt-3">
              <b-row class="mobile-tabs pb-4">
                <b-col class="text-center">
                  <b-link @click="prevTab" class="d-inline-block pr-4">
                    <font-awesome-icon icon="angle-double-left" />
                  </b-link>
                  {{ $t('event_registrierung.registrierung.label_weiteres') }}
                  <b-link
                    @click="handleSubmit(nextTab)"
                    class="d-inline-block pl-4"
                    disabled
                  >
                    <font-awesome-icon
                      class="text-muted"
                      icon="angle-double-right"
                    />
                  </b-link>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <!-- Feld: Dateiupload (PDF, Excel) -->
                  <ValidationProvider
                    :name="$t('event_registrierung.registrierung.label_dateiupload_name')"
                    rules="ext:pdf,xls,xlsx"
                    v-slot="validationContext"
                  >
                    <b-form-group
                      id="fieldset-files"
                      :label="$t('event_registrierung.registrierung.label_dateiupload')"
                      label-for="input-files"
                      :description="$t('event_registrierung.registrierung.label_dateiupload_description')"
                    >
                      <b-form-file
                        id="input-files"
                        v-model="uploads.files"
                        :state="getValidationState(validationContext)"
                        accept=".pdf,.xls,.xlsx"
                        aria-describedby="feedback-files"
                        class="mt-3"
                        multiple
                        plain
                        @change="convertFilesToBin"
                      ></b-form-file>
                      <b-form-invalid-feedback id="feedback-files">{{
                        validationContext.errors[0]
                      }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col md="6">
                  <!-- Feld: Logo Upload (JPG, PNG, GIF, BMP) -->
                  <ValidationProvider
                    :name="$t('event_registrierung.registrierung.label_logoupload_name')"
                    rules="ext:jpg,jpeg,png,gif,bmp"
                    v-slot="validationContext"
                  >
                    <b-form-group
                      id="fieldset-logo"
                      :label="$t('event_registrierung.registrierung.label_logoupload')"
                      label-for="input-logo"
                      :description="$t('event_registrierung.registrierung.label_logoupload_description')"
                    >
                      <b-form-file
                        id="input-logo"
                        v-model="uploads.logo"
                        :state="getValidationState(validationContext)"
                        accept=".jpg,.jpeg,.png,.gif,.bmp"
                        aria-describedby="feedback-logo"
                        class="mt-3"
                        plain
                        @change="convertLogoToBin"
                      ></b-form-file>
                      <b-form-invalid-feedback id="feedback-logo">{{
                        validationContext.errors[0]
                      }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <!-- Feld: Spezielle Bemerkungen zur Veranstaltung -->
                  <b-form-group
                    id="fieldset-remark"
                    :label="$t('event_registrierung.registrierung.label_spezielle_bemerkungen_zur_veranstaltung')"
                    label-for="input-remark"
                    :description="$t('event_registrierung.registrierung.label_spezielle_bemerkungen_zur_veranstaltung_description')"
                  >
                    <b-form-textarea
                      id="input-remark"
                      v-model="form.remark"
                      rows="5"
                      size="sm"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="12">
                  <!-- Feld: Telefon tagsüber / Mobile veröffentlichen? -->
                  <b-form-checkbox
                    id="input-publishMobile"
                    v-model="form.publishMobile"
                    :value="true"
                    :unchecked-value="false"
                    class="mb-3"
                  >
                    {{ $t('event_registrierung.registrierung.label_telefon_tagsueber_mobile_veroeffentlichen') }}
                    <small class="d-block text-muted">
                      {{ $t('event_registrierung.registrierung.label_telefon_tagsueber_mobile_veroeffentlichen_description') }}
                    </small>
                  </b-form-checkbox>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="12">
                  <!-- Feld: E-Mail veröffentlichen? -->
                  <b-form-checkbox
                    id="input-publishEmail"
                    v-model="form.publishEmail"
                    :value="true"
                    :unchecked-value="false"
                    class="mb-3"
                  >
                    {{ $t('event_registrierung.registrierung.label_email_veroeffentlichen') }}
                    <small class="d-block text-muted">
                      {{ $t('event_registrierung.registrierung.label_email_veroeffentlichen_description') }}
                    </small>
                  </b-form-checkbox>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="12">
                  <!-- Feld: Telefon privat veröffentlichen? -->
                  <b-form-checkbox
                    id="input-publishPhone"
                    v-model="form.publishPhone"
                    :value="true"
                    :unchecked-value="false"
                    class="mb-3"
                  >
                    {{ $t('event_registrierung.registrierung.label_telefon_privat_veroeffentlichen') }}
                  </b-form-checkbox>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <!-- Feld: Bemerkungen -->
                  <b-form-group
                    id="fieldset-note"
                    :label="$t('event_registrierung.registrierung.label_bemerkungen')"
                    label-for="input-note"
                    :description="$t('event_registrierung.registrierung.label_bemerkungen_description')"
                  >
                    <b-form-textarea
                      id="input-note"
                      v-model="form.note"
                      rows="5"
                      size="sm"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>
              
              <b-row>
                <b-col sm="12">
                  <!-- Akzeptanz Verhaltenscodex -->
                  <b-form-checkbox
                    id="input-verhaltenskodex"
                    v-model="helper.verhaltenskodex"
                    :value="true"
                    :unchecked-value="false"
                    class="mb-3"
                  >
                    <span v-html="$t('event_registrierung.registrierung.label_verhaltenskodex')"></span>
                    <small v-if="customError.verhaltenskodex.display && !helper.verhaltenskodex" class="d-block text-danger">{{ customError.verhaltenskodex.message }}</small>
                  </b-form-checkbox>
                </b-col>
              </b-row>

              <b-row align-h="between">
                <b-col cols="6" xl="2" lg="3" md="4">
                  <b-button block @click="prevTab" variant="light"
                    >{{ $t('event_registrierung.registrierung.label_back') }}</b-button
                  >
                </b-col>
                <b-col cols="6" xl="2" lg="3" md="4">
                  <b-button block type="submit"
                    >{{ $t('event_registrierung.registrierung.label_register') }}</b-button
                  >
                </b-col>
                <b-col cols="12" class="text-danger text-right" v-if="failed || (customError.verhaltenskodex.display && !helper.verhaltenskodex)">
                    <small>{{ $t('event_registrierung.registrierung.alert_form_validation_failed') }}</small>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-form>
      </ValidationObserver>
    </b-col>
    <b-col v-show="formFeedback.display">
      <b-alert :variant="formFeedback.type ? formFeedback.type : 'info'" show>
        <span class="d-block">{{ formFeedback.msg }}</span>
        <b-link @click="backToForm" v-show="formFeedback.backLink">
          {{ $t('event_registrierung.registrierung.label_zurück_zum_formular') }}
        </b-link>
        <b-link
          class="text-success"
          :to="`/${$i18n.locale}/veranstalter/event-erstellen`"
          v-show="formFeedback.registerLink"
        >
          {{ $t('event_registrierung.registrierung.label_weiterer_wettkampf_registrieren') }}
        </b-link>
      </b-alert>
    </b-col>
    <div
      v-if="displayPageLoader"
      id="pageLoader"
      class="d-flex justify-content-center align-items-center"
    >
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </b-row>
</template>

<script>
import { extend } from 'vee-validate';

extend('max_diplomas', {
  params: ['max', 'diploma_1', 'diploma_2'],
  validate(value, {max, diploma_1, diploma_2}) {
    return (Number(value) + Number(diploma_1) + Number(diploma_2)) <= max;
  },
  message: 'event_registrierung.registrierung.alert_form_validation_too_many_items'
});

extend('min_diplomas', {
  params: ['min', 'diploma_1', 'diploma_2'],
  validate(value, {min, diploma_1, diploma_2}) {
    return (Number(value) + Number(diploma_1) + Number(diploma_2)) >= min;
  },
  message: 'event_registrierung.registrierung.alert_form_validation_too_less_items'
});

extend('max_posters', {
  params: ['max', 'poster_1', 'poster_2'],
  validate(value, {max, poster_1, poster_2}) {
    return (Number(value) + Number(poster_1) + Number(poster_2)) <= max;
  },
  message: 'event_registrierung.registrierung.alert_form_validation_too_many_items'
});

extend('min_posters', {
  params: ['min', 'poster_1', 'poster_2'],
  validate(value, {min, poster_1, poster_2}) {
    return (Number(value) + Number(poster_1) + Number(poster_2)) >= min;
  },
  message: 'event_registrierung.registrierung.alert_form_validation_too_less_items'
});

extend('max_flyers', {
  params: ['max', 'flyer_1', 'flyer_2'],
  validate(value, {max, flyer_1, flyer_2}) {
    return (Number(value) + Number(flyer_1) + Number(flyer_2)) <= max;
  },
  message: 'event_registrierung.registrierung.alert_form_validation_too_many_items'
});

extend('max_helperShirts', {
  params: ['max', 'shirt_1', 'shirt_2', 'shirt_3'],
  validate(value, {max, shirt_1, shirt_2, shirt_3}) {
    return (Number(value) + Number(shirt_1) + Number(shirt_2) + Number(shirt_3)) <= max;
  },
  message: 'event_registrierung.registrierung.alert_form_validation_too_many_items'
});

export default {
  data() {
    // Rechne mindest Datum für DatePicker
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const minDate = new Date(today);

    return {
      user: null,
      form: {
        // das form objekt wird an die api geschickt
        // 1. Seite: Veranstaltungs-Kategorie
        nwpType: "Lov.Sat.NWPType.Sprint", // Wettkampftyp
        nwpCategory: "", // Wettkampfkategorie
        nwpEligible: "", // Art des Wettkampfes
        // 2. Seite: Veranstaltung
        schoolOrOrganisation: "", // Veranstalter (Verein)
        nwpName: "", // Wettkampfname
        sportFieldId: "", // Sportanlage / Stadion (ID des Stadions bei wahl aus Vorauswahl)
        sportField: "", // Sportanlage / Stadion
        nwpAddress: "", // Veranstaltungsadresse (Strasse)
        nwpZipCode: "", // PLZ
        venue: "", // Veranstaltungsort
        nwpCanton: "", // Kanton
        youngestCategory: "", // Jüngste Kategorie (M10W10 vorausgewählt)
        webpage: "", // Webseite
        startDate: "", // Austragungsdatum
        startTime: 0, // Startzeit
        expectedParticipantsCount: 0, // Erwartete Teilnehmerzahl
        hasEntryFee: "", // Startgeld erheben
        entryFee: 0.0, // Startgeld
        deadline: "", // Anmeldeschluss
        registrationOnSitePossible: false, // Nachmeldungen auf Platz möglich
        timeMeasure: "", // Zeitmessung
        participationLimitation: "", // Teilnahmeeinschränkung
        qualificationForNextStageLic: "", // Qualifikation für nächste Stufe
        usedEvaluationSoftware: "", // Benutzte Auswertungssoftware
        infomailOnNewRegistrations: "Lov.Sat.YesNo.No", // Infomail bei Teilnehmeranmeldung
        // 3. Seite: Kontakt
        organizerId: "", // Id des Organisators bei Wiederveranstaltern
        contactFirstName: "", // Vorname
        contactName: "", // Nachname
        contactAddress: "", // Adresse
        contactAddressLine2: "", // Adresszusatz
        contactZipCode: "", // PLZ
        contactCity: "", // Ort
        contactMobile: "", // Tel. tagsüber / Mobile
        contactPhone: "", // Telefon privat
        email: "", // E-Mail
        correspondenceLanguage: "", // Sprache
        contactOrganisation: "", // Verein / Organisation
        iban: "", // IBAN
        instituteName: "", // Name der Bank
        instituteOwner: "", // Kontoinhaber
        instituteOwnerAddress: "", // Adresse des Kontoinhabers
        instituteOwnerZip: "", // PLZ des Kontoinhabers
        instituteOwnerLocation: "", // Ort des Kontoinhabers
        // 4. Seite: Material
        startnumber: 0, // Anzahl Startnummern
        diplomaDE: 0, // Anzahl Diplome DE
        diplomaFR: 0, // Anzahl Diplome FR
        diplomaIT: 0, // Anzahl Diplome IT
        posterDE: 0, // Anzahl Plakate DE
        posterFR: 0, // Anzahl Plakate FR
        posterIT: 0, // Anzahl Plakate IT
        digitalPoster: false, // Digitale Version des Plakates
        flyerDE: 0, // Anzahl Flyer DE
        flyerFR: 0, // Anzahl Flyer FR
        flyerIT: 0, // Anzahl Flyer IT
        digitalFlyer: false, // Digitale Version des Flyers
        medal: 0, // Anzahl Medaillen
        medalsInterestLic: "", // Medaillen Interesse
        helperShirtS: 0, // Anzahl Helfershirts Grösse S
        helperShirtM: 0, // Anzahl Helfershirts Grösse M
        helperShirtL: 0, // Anzahl Helfershirts Grösse L
        helperShirtXL: 0, // Anzahl Helfershirts Grösse XL
        helperCap: 0, // Anzahl Helfercaps
        winnerPrize: 0, // Anzahl Siegerpreise
        podiumPrize: 0, // Anzahl Podestpreise
        participantGift: 0, // Anzahl Teilnehmergeschenke
        startingTent: 0, // Anzahl Startzelt
        tent: 0, // Anzahl Zelt
        banner: 0, // Anzahl Banner
        popOutFlag: 0, // Anzahl Pop-Out-Flags
        beachFlag: 0, // Anzahl Beach Flags
        archway: 0, // Anzahl Torbogen
        parasol: 0, // Anzahl Sonnenschirme
        table: 0, // Anzahl Tische
        pedestal: 0, // Anzahl Podeste
        coolAndCleanBanner: 0, // Anzahl Cool and Clean Banner
        // 4. Seite: Lieferadresse Material
        shippingFirstName: "", // Vorname
        shippingName: "", // Nachname
        shippingOrganisation: "", // Verein / Schule / Organisation
        shippingAddress: "", // Adresse
        shippingAddressLine2: "", // Lieferadresse (Zusatz)
        shippingZipCode: "", // PLZ
        shippingCity: "", // Ort
        shippingCanton: "", // Lieferkanton
        shippingMobile: "", // Telefon tagsüber / Mobile
        shippingPhone: "", // Telefon (Lieferinformation)
        shippingEmail: "", // E-Mail (Lieferinformation)
        // 5. Seite: Weiteres
        files: null, // Dateiupload (PDF, Excel)
        logo: null, // Logo Upload (JPG, PNG, GIF, BMP)
        remark: null, // Spezielle Bemerkungen zur Veranstaltung
        publishMobile: true, // Telefon tagsüber / Mobile veröffentlichen?
        publishEmail: true, // E-Mail veröffentlichen?
        publishPhone: false, // Telefon privat veröffentlichen?
        note: "", // Bemerkungen
      },
      helper: {
        // Helpers sind variablen, die für das formular verwendet werden, aber nicht an die api geschickt werden sollen
        requireBankAccount: true,
        verhaltenskodex: false, // Akzeptanz des Verhaltenskodex
        veranstaltungHomologation: "", // Homologation
        startTime: "", // Startzeit
        material: [], // Material
        datePicker: {
          minDate: minDate, // Mindest Datum für DatePicker fehlder
          maxDateDeadline: minDate, // Mindest Datum für DatePicker fehlder (wird später ersetzt durch das gesetzte datum beim Feld "startDate")
          labels: {
            labelPrevDecade: this.$t("bootstrap.datePicker.labelPrevDecade"),
            labelPrevYear: this.$t("bootstrap.datePicker.labelPrevYear"),
            labelPrevMonth: this.$t("bootstrap.datePicker.labelPrevMonth"),
            labelCurrentMonth: this.$t(
              "bootstrap.datePicker.labelCurrentMonth"
            ),
            labelNextMonth: this.$t("bootstrap.datePicker.labelNextMonth"),
            labelNextYear: this.$t("bootstrap.datePicker.labelNextYear"),
            labelNextDecade: this.$t("bootstrap.datePicker.labelNextDecade"),
            labelToday: this.$t("bootstrap.datePicker.labelToday"),
            labelSelected: this.$t("bootstrap.datePicker.labelSelected"),
            labelNoDateSelected: this.$t(
              "bootstrap.datePicker.labelNoDateSelected"
            ),
            labelCalendar: this.$t("bootstrap.datePicker.labelCalendar"),
            labelNav: this.$t("bootstrap.datePicker.labelNav"),
            labelHelp: this.$t("bootstrap.datePicker.labelHelp"),
          },
        },
        timePicker: {
          labels: {
            labelHours: this.$t("bootstrap.timePicker.labelHours"),
            labelMinutes: this.$t("bootstrap.timePicker.labelMinutes"),
            labelSeconds: this.$t("bootstrap.timePicker.labelSeconds"),
            labelIncrement: this.$t("bootstrap.timePicker.labelIncrement"),
            labelDecrement: this.$t("bootstrap.timePicker.labelDecrement"),
            labelSelected: this.$t("bootstrap.timePicker.labelSelected"),
            labelNoTimeSelected: this.$t(
              "bootstrap.timePicker.labelNoTimeSelected"
            ),
            labelCloseButton: this.$t("bootstrap.timePicker.labelCloseButton"),
          },
        },
        nwpQualificationForNextStage: [],
        qualificationForNextStageLic: {
          display: false,
          label: '',
          description: ''
        },
        maxMedals: 20,
        maxPoster: 0,
        minPoster: 0,
        maxDiploma: 0,
        minDiploma: 0,
        maxFlyer: 0,
        minFlyer: 0,
        maxHelperCap: 0,
        minHelperCap: 0,
        maxParasol: 0,
        minParasol: 0,
        maxHelperShirt: 0,
        disableNwpAddress: false,
        disableNwpZipCode: false,
        disableVenue: false,
        disableNwpCanton: false,
        disableYoungestCategory: false,
        disableHasEntryFee: false,
        disableExpectedParticipantsCount: false,
        disableTimeMeasure: false,
        disableUsedEvaluationSoftware: false,
        disableMedals: false,
        disableStartnumber: false,
        disableWinnerPrize: false,
        disablePodiumPrize: false,
        disableStartingTent: false,
        disableBanner: false,
        disablepopOutFlag: false,
        disablebeachFlag: false,
        disableArchway: false,
        disableParasol: false,
        disableTable: false,
        disablePedestal: false,
        disableCoolAndCleanBanner: false,
        customMaterialSortingOrder: [
          'Lov.Sat.NwpMaterial.Startnumber',
          'Lov.Sat.NwpMaterial.Diploma',
          'Lov.Sat.NwpMaterial.Poster',
          'Lov.Sat.NwpMaterial.Flyer',
          'Lov.Sat.NwpMaterial.Medal',
          'Lov.Sat.NwpMaterial.HelperShirt',
          'Lov.Sat.NwpMaterial.HelperCap',
          'Lov.Sat.NwpMaterial.WinnerPrize',
          'Lov.Sat.NwpMaterial.PodiumPrize',
          'Lov.Sat.NwpMaterial.ParticipantGift',
          'Lov.Sat.NwpMaterial.StartingTent',
          'Lov.Sat.NwpMaterial.Tent',
          'Lov.Sat.NwpMaterial.Banner',
          'Lov.Sat.NwpMaterial.PopOutFlag',
          'Lov.Sat.NwpMaterial.BeachFlag',
          'Lov.Sat.NwpMaterial.Archway',
          'Lov.Sat.NwpMaterial.Parasol',
          'Lov.Sat.NwpMaterial.Table',
          'Lov.Sat.NwpMaterial.Pedestal',
          'Lov.Sat.NwpMaterial.CoolAndCleanBanner',
        ],
        materialIdFieldPairs: [
          {
            'lic': 'Lov.Sat.NwpMaterial.Startnumber',
            'formfields': ['startnumber']
          },
          {
            'lic': 'Lov.Sat.NwpMaterial.Diploma',
            'formfields': ['diplomaDE', 'diplomaFR', 'diplomaIT']
          },
          {
            'lic': 'Lov.Sat.NwpMaterial.Poster',
            'formfields': ['posterDE', 'posterFR', 'posterIT', 'digitalPoster']
          },
          {
            'lic': 'Lov.Sat.NwpMaterial.Flyer',
            'formfields': ['flyerDE', 'flyerFR', 'flyerIT', 'digitalFlyer']
          },
          {
            'lic': 'Lov.Sat.NwpMaterial.Medal',
            'formfields': ['medal', 'medalsInterestLic']
          },
          {
            'lic': 'Lov.Sat.NwpMaterial.HelperShirt',
            'formfields': ['helperShirtS', 'helperShirtM', 'helperShirtL', 'helperShirtXL']
          },
          {
            'lic': 'Lov.Sat.NwpMaterial.HelperCap',
            'formfields': ['helperCap']
          },
          {
            'lic': 'Lov.Sat.NwpMaterial.WinnerPrize',
            'formfields': ['winnerPrize']
          },
          {
            'lic': 'Lov.Sat.NwpMaterial.PodiumPrize',
            'formfields': ['podiumPrize']
          },
          {
            'lic': 'Lov.Sat.NwpMaterial.ParticipantGift',
            'formfields': ['participantGift']
          },
          {
            'lic': 'Lov.Sat.NwpMaterial.StartingTent',
            'formfields': ['startingTent']
          },
          {
            'lic': 'Lov.Sat.NwpMaterial.Tent',
            'formfields': ['tent']
          },
          {
            'lic': 'Lov.Sat.NwpMaterial.Banner',
            'formfields': ['banner']
          },
          {
            'lic': 'Lov.Sat.NwpMaterial.PopOutFlag',
            'formfields': ['popOutFlag']
          },
          {
            'lic': 'Lov.Sat.NwpMaterial.BeachFlag',
            'formfields': ['beachFlag']
          },
          {
            'lic': 'Lov.Sat.NwpMaterial.Archway',
            'formfields': ['archway']
          },
          {
            'lic': 'Lov.Sat.NwpMaterial.Parasol',
            'formfields': ['parasol']
          },
          {
            'lic': 'Lov.Sat.NwpMaterial.Table',
            'formfields': ['table']
          },
          {
            'lic': 'Lov.Sat.NwpMaterial.Pedestal',
            'formfields': ['pedestal']
          },
          {
            'lic': 'Lov.Sat.NwpMaterial.CoolAndCleanBanner',
            'formfields': ['coolAndCleanBanner']
          },
        ],
      },
      uploads: {
        files: [],
        logo: null,
      },
      selectFields: {
        nwpType: [],
        nwpCategory: [],
        nwpEligibleLocal: [],
        nwpEligibleCanton: [],
        veranstaltungHomologation: [
          // Daten kommen von ??? (Noch in Abklärung)
          { value: "homologiert", text: this.$t('event_registrierung.registrierung.label_homologation_yes') },
          { value: "nicht-homologiert", text: this.$t('event_registrierung.registrierung.label_homologation_no') },
        ],
        sportFieldId: [],
        cantonList: [],
        hasEntryFee: [],
        timeMeasure: [],
        UsedEvaluationSoftwareNWP: [],
        correspondenceLanguage: [],
        youngestCategory: [],
        nwpQualificationForNextStage: [],
        nwpMaterialMedalsInterest: [],
      },
      displayForm: true,
      formFeedback: {
        display: false,
        backLink: false,
        registerLink: false,
        type: "",
        msg: "",
      },
      customError: {
        verhaltenskodex: {
          display: false,
          message: null,
        }
      },
      activeTab: 0,
      displayPageLoader: false,
    };
  },
  created() {
    this.getUserData(); // Sollte als erstes aufgerufen werden, da andere Funktionen von den Benutzerdaten abhängig sind
    this.getCantonsList();
    this.getNWPCategory();
    this.getNWPEligible();
    this.getNWPType();
    this.getYesNo();
    this.getTimeMeasure();
    this.getUsedEvaluationSoftwareNWP();
    this.getCorrespondenceLanguage();
    this.getYoungestCategory();
    this.getStadiumsHomologiert();
    this.getNWPQualificationForNextStage();
    this.getNWPMaterialMedalsInterest();
    this.prefillUserData();
  },
  methods: {
    getUserData() {
      this.user = this.$store.getters["veranstalter/getUser"];
    },
    prefillUserData() {
      if (this.user) {
        // 3. Seite: Kontakt
        this.form.contactFirstName = this.user.contactFirstName; // Vorname
        this.form.contactName = this.user.contactName; // Nachname
        this.form.contactAddress = this.user.contactAddress; // Adresse
        this.form.contactAddressLine2 = this.user.contactAddressLine2; // Adresszusatz
        this.form.contactZipCode = this.user.contactZipCode; // PLZ
        this.form.contactCity = this.user.contactCity; // Ort
        this.form.contactCanton = this.user.contactCanton; // Kanton
        this.form.contactMobile = this.user.contactMobile; // Tel. tagsüber / Mobile
        this.form.contactPhone = this.user.contactPhone; // Telefon privat
        this.form.email = this.user.email; // E-Mail
        this.form.correspondenceLanguage = this.user.correspondenceLanguage; // Sprache
        this.form.contactOrganisation = this.user.contactOrganisation; // Verein / Organisation
        this.form.iban = this.user.sasIban; // Verein / IBAN
        this.form.instituteName = this.user.sasInstituteName; // Verein / Name der Bank
        this.form.instituteOwner = this.user.sasInstituteOwner; // Verein / Kontoinhaber
        this.form.instituteOwnerAddress = this.user.sasInstituteOwnerAddress; // Verein / Adresse des Kontoinhabers
        this.form.instituteOwnerZip = this.user.sasInstituteOwnerZIP; // Verein / PLZ des Kontoinhabers
        this.form.instituteOwnerLocation = this.user.sasInstituteOwnerLocation; // Verein / Ort des Kontoinhabers
        // 4. Seite: Material
        this.form.shippingFirstName = this.user.contactFirstName; // Vorname
        this.form.shippingName = this.user.contactName; // Nachname
        this.form.shippingOrganisation = this.user.contactOrganisation; // Verein / Schule / Organisation
        this.form.shippingAddress = this.user.contactAddress; // Adresse
        this.form.shippingAddressLine2 = this.user.contactAddressLine2; // Adresszusatz
        this.form.shippingZipCode = this.user.contactZipCode; // PLZ
        this.form.shippingCity = this.user.contactCity; // Ort
        this.form.shippingCanton = this.user.contactCanton; // Lieferkanton
        this.form.shippingMobile = this.user.contactMobile; // Tel. tagsüber / Mobile
        this.form.shippingPhone = this.user.contactPhone; // Telefon (Lieferinformation)
        this.form.shippingEmail = this.user.email; // Telefon (Lieferinformation)
      }
    },
    mapLov(arr) {
      return arr.map((obj) => {
        var rObj = { value: "", text: "" };
        rObj.value = obj.lic;
        rObj.text = this.$t(obj.lic);
        return rObj;
      });
    },
    mapLovSportField(arr) {
      return arr.map((obj) => {
        var rObj = { value: "", text: "" };
        rObj.value = obj.id;
        rObj.text = this.$t(obj.lic);
        return rObj;
      });
    },
    mapStadium(arr) {
      return arr.map((obj) => {
        var rObj = { value: "", text: "" };
        rObj.value = obj.id;
        rObj.text = obj.city + ": " + obj.name;
        return rObj;
      });
    },
    filterLovList(data, filter = "") {
      var returnData = [];
      data.forEach((obj) => {
        var objArr = obj.lic.split(".");
        if (objArr[objArr.length - 1].startsWith(filter)) {
          returnData.push(obj);
        }
      });
      return returnData;
    },
    getCantonsList() {
      this.$store
        .dispatch("lov/getCantonList")
        .then((resp) => {
          this.selectFields.cantonList = this.mapLov(resp.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getNWPCategory() {
      this.$store
        .dispatch("lov/getNWPCategory")
        .then((resp) => {
          this.selectFields.nwpCategory = this.mapLov(
            this.filterLovList(resp.data, "Sprint")
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getNWPEligible() {
      this.$store
        .dispatch("lov/getNWPEligible")
        .then((resp) => {
          this.selectFields.nwpEligibleLocal = this.mapLov(
            this.filterLovList(resp.data, "SprintLocal")
          );
          this.selectFields.nwpEligibleCanton = this.mapLov(
            this.filterLovList(resp.data, "SprintCanton")
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getNWPType() {
      this.$store
        .dispatch("lov/getNWPType")
        .then((resp) => {
          this.selectFields.nwpType = this.mapLov(
            this.filterLovList(resp.data, "Sprint")
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getTimeMeasure() {
      this.$store
        .dispatch("lov/getTimeMeasure")
        .then((resp) => {
          this.selectFields.timeMeasure = this.mapLov(resp.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCorrespondenceLanguage() {
      this.$store
        .dispatch("lov/getCorrespondenceLanguage")
        .then((resp) => {
          this.selectFields.correspondenceLanguage = this.mapLov(resp.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getUsedEvaluationSoftwareNWP() {
      this.$store
        .dispatch("lov/getUsedEvaluationSoftwareNWP")
        .then((resp) => {
          this.selectFields.UsedEvaluationSoftwareNWP = this.mapLov(resp.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getStadiumsHomologiert() {
      this.$store
        .dispatch("stadium/getStadiumsHomologiert")
        .then((resp) => {
          this.selectFields.sportFieldId = this.mapStadium(resp.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getYesNo() {
      this.$store
        .dispatch("lov/getYesNo")
        .then((resp) => {
          const yesNoValues = this.mapLov(resp.data);
          this.selectFields.hasEntryFee = yesNoValues;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getYoungestCategory() {
      this.$store
        .dispatch("lov/getYoungestCategory")
        .then((resp) => {
          this.selectFields.youngestCategory = this.mapLov(resp.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getNWPQualificationForNextStage() {
      this.$store
        .dispatch("lov/getNWPQualificationForNextStage")
        .then((resp) => {
          this.helper.nwpQualificationForNextStage = this.mapLov(resp.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getNWPMaterialMedalsInterest() {
      this.$store
        .dispatch("lov/getNWPMaterialMedalsInterest")
        .then((resp) => {
          this.selectFields.nwpMaterialMedalsInterest = this.mapLov(resp.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getNWPQualificationForNextStageLocal() {
      return this.helper.nwpQualificationForNextStage;
    },
    getNWPQualificationForNextStageCantonal() {
      return this.helper.nwpQualificationForNextStage.filter((option) => {
        const allowedCategories = [
          'Lov.Sat.NWPQualificationForNextStage.WinnerPerCategory', // Sieger/in pro Kategorie
          'Lov.Sat.NWPQualificationForNextStage.Top2PerCategory', // Top 2 pro Kategorie
        ];

        return allowedCategories.includes(option.value);
      });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    getNWPMaterials(value) {
      var data = {
        sprintLA: value === 'Lov.Sat.NWPCategory.SprintLocalContest' ? true : false, // Visanasprint: Lokale Ausscheidung
        sprintKF: value === 'Lov.Sat.NWPCategory.SprintCantonFinal' ? true : false, // Visanasprint: Kantonalfinal
        sprintSF: value === 'Lov.Sat.NWPCategory.SprintSwissFinal' ? true : false, // Visanasprint: Schweizer Final
        milleLA: value === 'Lov.Sat.NWPCategory.MilleLocalContest' ? true : false, // Mille Gruyère: Lokale Ausscheidung
        milleRF: value === 'Lov.Sat.NWPCategory.MilleCantonFinal' ? true : false, // Mille Gruyère: Kantonalfinal
        milleSF: value === 'Lov.Sat.NWPCategory.MilleSwissFinal' ? true : false, // Mille Gruyère: Schweizer Final
      }

      this.$store
        .dispatch("wettkampf/getWettkampfMaterial", data)
        .then((resp) => {
          this.helper.material = resp.data;
          this.helper.material = this.helper.material.filter((material) => {
            return ((data.sprintLA && material.sprintLA) || (data.sprintKF && material.sprintKF) || (data.sprintSF && material.sprintSF) || (data.milleLA && material.milleLA) || (data.milleRF && material.milleRF) || (data.milleSF && material.milleSF))
          })
          this.helper.material.map((material) => {
            if (material.image) {
              const fileExt = material.image.fileName.split('.').pop();
              material.image.src = `data:image/${fileExt};base64,${material.image.fileContent}`
            }
            
            material.descriptionDE = material.descriptionDE.replace(/(?:\r\n|\r|\n)/g, '<br>')
            material.descriptionFR = material.descriptionFR.replace(/(?:\r\n|\r|\n)/g, '<br>')
            material.descriptionIT = material.descriptionIT.replace(/(?:\r\n|\r|\n)/g, '<br>')
            
            material.informationDE = material.informationDE.replace(/(?:\r\n|\r|\n)/g, '<br>')
            material.informationFR = material.informationFR.replace(/(?:\r\n|\r|\n)/g, '<br>')
            material.informationIT = material.informationIT.replace(/(?:\r\n|\r|\n)/g, '<br>')

            // Sortiere Materialien anhand der manuell definierten Sortierreihenfolge.
            // Wenn das Material in der bestimmten Sortierung nicht gefunden werden konnte, setze als Order-Parameter 999999.
            // So wird das betroffene Material ganz hinten am Array angehängt.
            const orderParam = this.helper.customMaterialSortingOrder.indexOf(material.materialTitleLic)
            material.order = orderParam !== -1 ? orderParam : 999999;
          })

          // Sortiere Material anhand des Order-Parameters
          this.helper.material.sort((a, b) => a.order - b.order);
        })
        .catch((err) => {
          console.log('getNWPMaterials (error)', err);
        });

    },
    prevTab() {
      if (this.activeTab == 0) {
        this.$router.push(
          "/" + this.$i18n.locale + "/veranstalter/event-erstellen"
        );
        return;
      }
      this.activeTab = --this.activeTab;
      this.$helpers.scrollToTop();
    },
    nextTab() {
      this.activeTab = ++this.activeTab;
      this.$helpers.scrollToTop();
    },
    backToForm() {
      this.$helpers.scrollToTop();
      this.resetFormFeedback();
      this.displayForm = true;
    },
    resetFormFeedback() {
      this.formFeedback.display = false;
      this.formFeedback.type = "";
      this.formFeedback.msg = "";
      this.formFeedback.backLink = false;
      this.formFeedback.registerLink = false;
    },
    resetSportanlage() {
      this.form.sportFieldId = "";
      this.form.sportField = "";
      this.form.nwpAddress = "";
      this.form.nwpZipCode = "";
      this.form.venue = "";
      this.form.nwpCanton = "";
      this.helper.disableNwpAddress = false;
      this.helper.disableNwpZipCode = false;
      this.helper.disableVenue = false;
      this.helper.disableNwpCanton = false;
    },
    expectedParticipantsCountUpdated(value) {
      this.prefillStartnumber();
      this.prefillParticipantGift();
    },
    prefillStartnumber() {
      //befor:
      //this.form.startnumber = this.form.expectedParticipantsCount;
      //now: StartNumber should be prefilled with 0, for validation max value expectedParticipantsCount is still used
      //TODO: this method can be removed in the future
      this.form.startnumber = this.form.nwpCategory === 'Lov.Sat.NWPCategory.SprintSwissFinal' ? 400 : 0;

    },
    prefillParticipantGift() {
      this.form.participantGift = this.form.expectedParticipantsCount;
    },
    prefillStadiumData() {
      var stadiumID = this.form.sportFieldId;
      var stadium = this.$store.getters["stadium/getStadiumHomologiert"](stadiumID);
      var canton = this.$store.getters["lov/getCanton"](stadium.canton);

      if (stadium.name !== undefined && stadium.name != '') {
        this.form.sportField = stadium.name;
      }

      if (stadium.addressLine1 !== undefined && stadium.addressLine1 != '') {
        this.form.nwpAddress = stadium.addressLine1;
        this.helper.disableNwpAddress = true; // Disable dieses Formular Feld, da es vorausgefüllt ist
      }

      if (stadium.zip !== undefined && stadium.zip != '') {
        this.form.nwpZipCode = stadium.zip;
        this.helper.disableNwpZipCode = true; // Disable dieses Formular Feld, da es vorausgefüllt ist
      }

      if (stadium.city !== undefined && stadium.city != '') {
        this.form.venue = stadium.city;
        this.helper.disableVenue = true; // Disable dieses Formular Feld, da es vorausgefüllt ist
      }

      if (canton.lic !== undefined && canton.lic != '') {
        this.form.nwpCanton = canton.lic;
        this.helper.disableNwpCanton = true; // Disable dieses Formular Feld, da es vorausgefüllt ist
      }
    },
    prefillShippingFirstName(value) {
      this.form.shippingFirstName = value;
    },
    prefillShippingName(value) {
      this.form.shippingName = value;
    },
    prefillShippingOrganisation(value) {
      this.form.shippingOrganisation = value;
    },
    prefillShippingAddress(value) {
      this.form.shippingAddress = value;
    },
    prefillShippingAddressLine2(value) {
      this.form.shippingAddressLine2 = value;
    },
    prefillShippingZipCode(value) {
      this.form.shippingZipCode = value;
    },
    prefillShippingCity(value) {
      this.form.shippingCity = value;
    },
    prefillShippingCanton(value) {
      this.form.shippingCanton = value;
    },
    prefillShippingMobile(value) {
      this.form.shippingMobile = value;
    },
    prefillShippingPhone(value) {
      this.form.shippingPhone = value;
    },
    prefillShippingEmail(value) {
      this.form.shippingEmail = value;
    },
    setMaxMedals(value) {
      if ('Lov.Sat.YoungestCategory.M6W6' == value) {
        this.helper.maxMedals = 21;
        this.form.medal = 20;
      } else if ('Lov.Sat.YoungestCategory.M7W7' == value) {
        this.helper.maxMedals = 19;
        this.form.medal = 18;
      } else if ('Lov.Sat.YoungestCategory.M10W10' == value) {
        this.helper.maxMedals = 13;
        this.form.medal = 12;
      } else {
        this.helper.maxMedals = 20;
        this.form.medal = 0;
      }
    },
    setWinnerPrize(value) {
      // Bei Kantonalfinal
      if (this.form.nwpCategory === 'Lov.Sat.NWPCategory.SprintCantonFinal') {
        if ('Lov.Sat.YoungestCategory.M6W6' == value) {
          this.helper.disableWinnerPrize = true;
          this.form.winnerPrize = 20;
        } else if ('Lov.Sat.YoungestCategory.M7W7' == value) {
          this.helper.disableWinnerPrize = true;
          this.form.winnerPrize = 18;
        } else if ('Lov.Sat.YoungestCategory.M10W10' == value) {
          this.helper.disableWinnerPrize = true;
          this.form.winnerPrize = 12;
        } else {
          this.helper.disableWinnerPrize = false;
          this.form.winnerPrize = 0;
        } 
      }
    },
    setPodiumPrize(value) {
      // Bei Kantonalfinal
      if (this.form.nwpCategory === 'Lov.Sat.NWPCategory.SprintCantonFinal') {
        if ('Lov.Sat.YoungestCategory.M6W6' == value) {
          this.helper.disablePodiumPrize = true;
          this.form.podiumPrize = 60;
        } else if ('Lov.Sat.YoungestCategory.M7W7' == value) {
          this.helper.disablePodiumPrize = true;
          this.form.podiumPrize = 54;
        } else if ('Lov.Sat.YoungestCategory.M10W10' == value) {
          this.helper.disablePodiumPrize = true;
          this.form.podiumPrize = 36;
        } else {
          this.helper.disablePodiumPrize = false;
          this.form.podiumPrize = 0;
        } 
      }
    },
    validateHelperShirts() {
      const sumHelperShirts = Number(this.form.helperShirtS) + Number(this.form.helperShirtM) + Number(this.form.helperShirtL) + Number(this.form.helperShirtXL);

      if (sumHelperShirts > this.helper.maxHelperShirt) {

        const validationMessage = this.$t(`event_registrierung.registrierung.alert_form_validation_too_many_items`);
        
        this.$refs.observer.setErrors({
          helperShirtS: [validationMessage],
          helperShirtM: [validationMessage],
          helperShirtL: [validationMessage],
          helperShirtXL: [validationMessage]
        });
      } else {
        this.$refs.observer.setErrors({
          helperShirtS: null,
          helperShirtM: null,
          helperShirtL: null,
          helperShirtXL: null
        });
      }
    },
    youngestCategoryUpdated(value) {
      this.setMaxMedals(value);
      this.setWinnerPrize(value);
      this.setPodiumPrize(value);
    },
    nwpCategoryUpdated(value) {
      this.setNwpCategoryProperties(value);
      this.getNWPMaterials(value);
    },
    setNwpCategoryProperties(value) {
      this.resetMaterials();
      this.selectFields.nwpQualificationForNextStage = [];
      this.helper.qualificationForNextStageLic.display = false;
      this.helper.disableHasEntryFee = false;
      this.helper.disableYoungestCategory = false;
      this.helper.disableExpectedParticipantsCount = false;
      this.helper.disableTimeMeasure = false;
      this.helper.disableUsedEvaluationSoftware = false;
      this.helper.requireBankAccount = true;
      this.form.nwpEligible = "";

      // Wenn als Kategorie der Schweizer Final gewählt wird
      if ('Lov.Sat.NWPCategory.SprintSwissFinal' == value) {
        // Fixiere Erhebung Startgeld auf "Nein"
        this.form.hasEntryFee = 'Lov.Sat.YesNo.No';
        this.helper.disableHasEntryFee = true;

        // Fixiere jüngste Kategorie auf M10W10
        this.form.youngestCategory = 'Lov.Sat.YoungestCategory.M10W10';
        this.helper.disableYoungestCategory = true;

        // Fixiere erwartete Teilnehmerzahl auf 400
        this.form.expectedParticipantsCount = 400;
        this.helper.disableExpectedParticipantsCount = true;

        // Fixiere Zeitmessung auf "Elektronisch"
        this.form.timeMeasure = 'Lov.Sat.TimeMeasure.Electronic';
        this.helper.disableTimeMeasure = true;

        // Fixiere Auswertungssoftware auf "TAF3 von Seltex"
        this.form.usedEvaluationSoftware = 'Lov.Sat.UsedEvaluationSoftwareNWP.TAF3';
        this.helper.disableUsedEvaluationSoftware = true;

        // Fixiere Anzahl Medaillensätze auf 13
        this.form.medal = 13;
        this.helper.disableMedals = true;

        // Fixiere Anzahl Startnummern auf 400
        this.form.startnumber = 400;
        this.helper.disableStartnumber = true;

        // Fixiere Anzahl Siegerpreise auf 40
        this.form.winnerPrize = 15;
        this.helper.disableWinnerPrize = true;

        // Fixiere Anzahl Podestpreise auf 40
        this.form.podiumPrize = 40;
        this.helper.disablePodiumPrize = true;

        // Fixiere Anzahl Startzelte auf 2
        this.form.startingTent = 2;
        this.helper.disableStartingTent = true;
        
        // Fixiere Anzahl Banner auf 60
        this.form.banner = 60;
        this.helper.disableBanner = true;
        
        // Fixiere Anzahl Pop-Out Flags auf 10
        this.form.popOutFlag = 10;
        this.helper.disablepopOutFlag = true;
        
        // Fixiere Anzahl Beach Flags auf 10
        this.form.beachFlag = 10;
        this.helper.disablebeachFlag = true;
        
        // Fixiere Anzahl Torbögen auf 1
        this.form.archway = 1;
        this.helper.disableArchway = true;
        
        // Fixiere Anzahl Tische auf 2
        this.form.table = 2;
        this.helper.disableTable = true;
        
        // Fixiere Anzahl Podeste auf 1
        this.form.pedestal = 1;
        this.helper.disablePedestal = true;
        
        // Fixiere Anzahl Cool and Clean Banner auf 4
        this.form.coolAndCleanBanner = 4;
        this.helper.disableCoolAndCleanBanner = true;

        // Setze min. und max. Werte für Bestellbare Plakate
        this.helper.minPoster = 0;
        this.helper.maxPoster = 10;
        
        // Setze min. und max. Werte für Bestellbare Diplome
        this.helper.minDiploma = 150;
        this.helper.maxDiploma = 200;

        // Setze min. und max. Werte für Helfercaps
        this.helper.minHelperCap = 0;
        this.helper.maxHelperCap = 50;
        
        // Setze min. und max. Werte für Sonnenschirme und fülle Standardwert aus
        this.form.parasol = 6;
        this.helper.minParasol = 0;
        this.helper.maxParasol = 13;

        // Setze maximal bestellbare Helfershirts
        this.helper.maxHelperShirt = 50;
      }

      // Wenn als Kategorie "Kantonalfinal" gewählt wird
      if ('Lov.Sat.NWPCategory.SprintCantonFinal' == value) {
        // Fixiere Zeitmessung auf "Elektronisch"
        this.form.timeMeasure = 'Lov.Sat.TimeMeasure.Electronic';
        this.helper.disableTimeMeasure = true;

        // Fixiere Auswertungssoftware auf "TAF3 von Seltex"
        this.form.usedEvaluationSoftware = 'Lov.Sat.UsedEvaluationSoftwareNWP.TAF3';
        this.helper.disableUsedEvaluationSoftware = true;

        // Zeige Inputfeld für Quali-Bedingungen für die nächste Runde an
        this.helper.qualificationForNextStageLic.label = this.$t('event_registrierung.registrierung.label_qualifikation_für_schweizer_final');
        this.helper.qualificationForNextStageLic.description = this.$t('event_registrierung.registrierung.label_qualifikation_für_schweizer_final_description');
        this.selectFields.nwpQualificationForNextStage = this.getNWPQualificationForNextStageCantonal();
        this.helper.qualificationForNextStageLic.display = true;

        // Setze Anzahl Siegerpreise
        this.setWinnerPrize(this.form.youngestCategory);
        
        // Setze Anzahl Podiumpreise
        this.setPodiumPrize(this.form.youngestCategory);

        // Fixiere Anzahl Startzelte auf 1
        this.form.startingTent = 1;
        this.helper.disableStartingTent = true;
        
        // Fixiere Anzahl Banner auf 60
        this.form.banner = 60;
        this.helper.disableBanner = true;
        
        // Fixiere Anzahl Pop-Out Flags auf 10
        this.form.popOutFlag = 10;
        this.helper.disablepopOutFlag = true;
        
        // Fixiere Anzahl Beach Flags auf 10
        this.form.beachFlag = 10;
        this.helper.disablebeachFlag = true;
        
        // Fixiere Anzahl Torbögen auf 1
        this.form.archway = 1;
        this.helper.disableArchway = true;
        
        // Fixiere Anzahl Tische auf 2
        this.form.table = 2;
        this.helper.disableTable = true;
        
        // Fixiere Anzahl Podeste auf 1
        this.form.pedestal = 1;
        this.helper.disablePedestal = true;
        
        // Fixiere Anzahl Cool and Clean Banner auf 4
        this.form.coolAndCleanBanner = 4;
        this.helper.disableCoolAndCleanBanner = true;
        
        // Fixiere Anzahl Sonnenschirme auf 10
        this.form.parasol = 10;
        this.helper.minParasol = 0;
        this.helper.maxParasol = 10;
        this.helper.disableParasol = true;

        // Setze min. und max. Werte für Bestellbare Plakate
        this.helper.minPoster = 0;
        this.helper.maxPoster = 10;

        // Setze min. und max. Werte für Bestellbare Diplome
        this.helper.minDiploma = 0;
        this.helper.maxDiploma = 200;
        
        // Setze min. und max. Werte für Helfercaps
        this.helper.minHelperCap = 0;
        this.helper.maxHelperCap = 30;

        // Setze maximal bestellbare Helfershirts
        this.helper.maxHelperShirt = 30;
      }

      // Wenn als Kategorie "Lokale Ausscheidungen" gewählt wird
      if ('Lov.Sat.NWPCategory.SprintLocalContest' == value) {
        // Zeige Inputfeld für Quali-Bedingungen für die nächste Runde an
        this.helper.qualificationForNextStageLic.label = this.$t('event_registrierung.registrierung.label_qualifikation_für_kantonalfinal');
        this.helper.qualificationForNextStageLic.description = this.$t('event_registrierung.registrierung.label_qualifikation_für_kantonalfinal_description');
        this.selectFields.nwpQualificationForNextStage = this.getNWPQualificationForNextStageLocal();
        this.helper.qualificationForNextStageLic.display = true;

        // Felder für Bankangaben als Nicht-Pflichtfelder definieren
        this.helper.requireBankAccount = false;

        // Setze min. und max. Werte für Bestellbare Plakate
        this.helper.minPoster = 0;
        this.helper.maxPoster = 10;

        // Setze min. und max. Werte für Bestellbare Diplome
        this.helper.minDiploma = 0;
        this.helper.maxDiploma = 200;
      }

      this.prefillStartnumber();
      this.prefillParticipantGift();
    },
    resetMaterials() {
      /**
       * Setze alle kategoriespezifischen Material-Einstellungen zurück
       */
      this.form.startnumber = 0; // Anzahl Startnummern
      this.form.diplomaDE = 0; // Anzahl Diplome DE
      this.form.diplomaFR = 0; // Anzahl Diplome FR
      this.form.diplomaIT = 0; // Anzahl Diplome IT
      this.form.posterDE = 0; // Anzahl Plakate DE
      this.form.posterFR = 0; // Anzahl Plakate FR
      this.form.posterIT = 0; // Anzahl Plakate IT
      this.form.digitalPoster = false; // Digitale Version des Plakates
      this.form.flyerDE = 0; // Anzahl Flyer DE
      this.form.flyerFR = 0; // Anzahl Flyer FR
      this.form.flyerIT = 0; // Anzahl Flyer IT
      this.form.digitalFlyer = false; // Digitale Version des Flyers
      this.form.medal = 0; // Anzahl Medaillen
      this.form.medalsInterestLic = ""; // Medaillen Interesse
      this.form.helperShirtS = 0; // Anzahl Helfershirts Grösse S
      this.form.helperShirtM = 0; // Anzahl Helfershirts Grösse M
      this.form.helperShirtL = 0; // Anzahl Helfershirts Grösse L
      this.form.helperShirtXL = 0; // Anzahl Helfershirts Grösse XL
      this.form.helperCap = 0; // Anzahl Helfercaps
      this.form.winnerPrize = 0; // Anzahl Siegerpreise
      this.form.podiumPrize = 0; // Anzahl Podestpreise
      this.form.participantGift = 0; // Anzahl Teilnehmergeschenke
      this.form.startingTent = 0; // Anzahl Startzelt
      this.form.tent = 0; // Anzahl Zelt
      this.form.banner = 0; // Anzahl Banner
      this.form.popOutFlag = 0; // Anzahl Pop-Out-Flags
      this.form.beachFlag = 0; // Anzahl Beach Flags
      this.form.archway = 0; // Anzahl Torbogen
      this.form.parasol = 0; // Anzahl Sonnenschirme
      this.form.table = 0; // Anzahl Tische
      this.form.pedestal = 0; // Anzahl Podeste
      this.form.coolAndCleanBanner = 0; // Anzahl Cool and Clean Banner

      this.helper.maxMedals = 20;
      this.helper.maxPoster = 0;
      this.helper.minPoster = 0;
      this.helper.maxDiploma = 0;
      this.helper.minDiploma = 0;
      this.helper.maxFlyer = 0;
      this.helper.minFlyer = 0;
      this.helper.maxHelperCap = 0;
      this.helper.minHelperCap = 0;
      this.helper.maxParasol = 0;
      this.helper.minParasol = 0;
      this.helper.maxHelperShirt = 0;

      this.helper.disableMedals = false;
      this.helper.disableStartnumber = false;
      this.helper.disableWinnerPrize = false;
      this.helper.disablePodiumPrize = false;
      this.helper.disableStartingTent = false;
      this.helper.disableBanner = false;
      this.helper.disablepopOutFlag = false;
      this.helper.disablebeachFlag = false;
      this.helper.disableArchway = false;
      this.helper.disableParasol = false;
      this.helper.disableTable = false;
      this.helper.disablePedestal = false;
      this.helper.disableCoolAndCleanBanner = false;
    },
    removeUnusedMaterials(data) {
      const originalData = data;
      return new Promise((resolve, reject) => {
        try {
          // Erhalte Materialien, welche bei diesem Event NICHT gewählt werden können
          const materialUnused = this.helper.materialIdFieldPairs.filter(item => {
            for (let index = 0; index < this.helper.material.length; index++) {
              if (item.lic === this.helper.material[index].materialTitleLic) {
                return false;
              }
            }
            return true;
          });
          
          // Erhalte Array mit allen NICHT verwendeten Feldnamen
          var fieldsUnused = [];
          for (let index = 0; index < materialUnused.length; index++) {
            fieldsUnused = fieldsUnused.concat(materialUnused[index].formfields.filter((item) => fieldsUnused.indexOf(item) < 0));
          }
    
          // Erhalte Materialien, welche bei diesem Event gewählt werden können
          const materialUsed = this.helper.materialIdFieldPairs.filter(item => {
            for (let index = 0; index < this.helper.material.length; index++) {
              if (item.lic === this.helper.material[index].materialTitleLic) {
                return true;
              }
            }
            return false;
          });
    
          // Erhalte Array mit allen verwendeten Feldnamen
          var fieldsUsed = [];
          for (let index = 0; index < materialUsed.length; index++) {
            fieldsUsed = fieldsUsed.concat(materialUsed[index].formfields.filter((item) => fieldsUsed.indexOf(item) < 0));
          }
    
          // Behandle Sonderfall Medaillen/Medaillen-Interesse
          if (fieldsUsed.includes('medal') && fieldsUsed.includes('medalsInterestLic')) {
            if (this.form.nwpCategory === 'Lov.Sat.NWPCategory.SprintLocalContest') {
              var itemIndex = fieldsUsed.findIndex((item) => item === 'medal')
              if (itemIndex !== -1) {
                fieldsUnused.push(fieldsUsed.splice(itemIndex, 1));
              }
            }
            if (this.form.nwpCategory === 'Lov.Sat.NWPCategory.SprintCantonFinal' || this.form.nwpCategory === 'Lov.Sat.NWPCategory.SprintSwissFinal') {
              var itemIndex = fieldsUsed.findIndex((item) => item === 'medalsInterestLic')
              if (itemIndex !== -1) {
                fieldsUnused.push(fieldsUsed.splice(itemIndex, 1));
              }
            }
          }
    
          // Entferne nicht verwendete Felder von Formular Datenobjekt
          for (let index = 0; index < fieldsUnused.length; index++) {
            delete data[fieldsUnused[index]];
          }
          
          resolve(data);
        } catch (error) {
          reject(originalData);
        }
      });
    },
    convertStartTimeToMs(time) {
      var [h, m, s] = time.split(":"); // Split H:m:i bei ":" in ein array
      var d = new Date(),
        e = new Date(d);
      this.form.startTime = e.setHours(h, m, s, 0) - d.setHours(0, 0, 0, 0);
    },
    updateMaxDateDeadline(date) {
      this.helper.datePicker.maxDateDeadline = new Date(date);
      this.form.deadline = ""; // Setze Anmeldeschluss zurück wenn Austragunsdatum gesetzt/geändert wird
    },
    convertFilesToBin() {
      setTimeout(() => {
        // Convert uploaded files (multiupload) to binary (base64)
        if (this.uploads.files.length > 0) {
          var fileArr = [];
          this.uploads.files.forEach((file) => {
            this.file2bin(file)
              .then((resp) => {
                fileArr.push(resp);
              })
              .catch((err) => {
                console.log(err);
              });
          });
          this.form.files = fileArr;
        }
      }, 1000);
    },
    convertLogoToBin() {
      // Convert uploaded logo to binary (base64)
      setTimeout(() => {
        if (this.uploads.logo !== null) {
          this.file2bin(this.uploads.logo)
            .then((resp) => {
              this.form.logo = resp;
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }, 1000);
    },
    formatIBANInput(iban) {
      if (iban === '' || iban === undefined || iban === null) return '';
      const maxIBANLength = 21; // IBAN-Länge von 21 ist Schweiz spezifisch (Kläre noch ab mit Alain wie wir das handhaben sollen)
      var ibanArr = iban
        .toString()
        .replace(/\s/g, "")
        .substr(0, maxIBANLength)
        .match(/.{1,4}/g);
      return ibanArr.join(" ");
    },
    file2bin(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
          var fileObj = {
            fileName: file.name,
            fileContent: reader.result.split(",")[1],
          };
          resolve(fileObj);
        };
        reader.onerror = () => {
          reject(reader.error);
        };
        reader.readAsDataURL(file);
      });
    },
    async registerEvent() {
      this.displayPageLoader = true;

      // Prüfe, ob der Verhaltenskodex akzeptiert wurde
      if (this.helper.verhaltenskodex === false) {
        this.customError.verhaltenskodex.message = this.$t('event_registrierung.registrierung.label_verhaltenskodex_alert');
        this.customError.verhaltenskodex.display = true;
        this.displayPageLoader = false;
        return;
      }

      // Entferne nicht benutzte Material-Parameter
      this.form = await this.removeUnusedMaterials(this.form);

      // Fülle Organisator ID aus, wenn gesetzt
      if (this.user !== null && this.user.organisatorId !== undefined && this.user.organisatorId != "" && this.user.organisatorId !== null) {
        this.form.organizerId = this.user.organisatorId;
      }

      // Sende formular daten an api
      this.$store
        .dispatch("wettkampf/setWettkampf", this.form)
        .then(() => {
          this.displayForm = false; // Hide Form on success
          this.formFeedback.msg = this.$t('event_registrierung.registrierung.alert_event_created_successfully');
          this.formFeedback.type = "success";
          this.formFeedback.display = true;
          this.formFeedback.registerLink = true;
        })
        .catch(() => {
          this.displayForm = false; // Hide Form on success
          this.formFeedback.msg = this.$t('event_registrierung.registrierung.alert_event_created_fail');
          this.formFeedback.type = "danger";
          this.formFeedback.backLink = true;
          this.formFeedback.display = true;
        })
        .then(() => {
          this.$helpers.scrollToTop();
          this.displayPageLoader = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#pageLoader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(239, 239, 239, 0.6);
}
</style>