<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col>
          <h1>
            {{ $t("event_registrierung.einstieg.label_veranstalter_werden") }}
          </h1>
        </b-col>
      </b-row>
      <b-row class="p-4 bg-mellow" style="margin-bottom: 8rem;">
        <b-col cols="12" class="pb-4">
          <h1 class="d-inline-block pr-1 h3">{{ $t('event_registrierung.registrierung.label_page_title') }}</h1>
          <span v-if="user" class="d-inline-block text-muted"
            >({{ $t('event_registrierung.registrierung.label_logged_in') }} {{ user.email }} -
            <b-link
              href="javascript:;"
              @click="logoutUser"
              class="text-muted font-italic"
              >{{ $t('event_registrierung.registrierung.label_logout') }}</b-link
            >)</span
          >
        </b-col>
        <b-col cols="12">
          <EventRegistrierung :key="eventRegistrierungKey" />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import EventRegistrierung from "@/views/Formulare/EventRegistrierung";

export default {
  components: {
    EventRegistrierung,
  },
  data() {
    return {
      eventRegistrierungKey: 1, // Key wird verwendet um beim Component EventRegistrierung einen Reload zu forcieren (Änderung des Keys forciert reload)
      user: false,
    };
  },
  created() {
    this.getUserData();
  },
  methods: {
    getUserData() {
      this.user = this.$store.getters["veranstalter/getUser"];
    },
    logoutUser() {
      this.$store
        .dispatch("veranstalter/logout")
        .then(() => {
          console.log("Benutzer abgemeldet");
        })
        .catch((err) => {
          console.log(err);
        })
        .then(() => {
          this.user = this.$store.getters["veranstalter/getUser"];
          this.eventRegistrierungKey++; // Forciere reload des Formulars
        });
    },
  },
};
</script>